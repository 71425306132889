import axios from 'axios'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { useHistory } from 'react-router-dom'
import { compose } from 'redux'
import {
  Dimmer,
  Dropdown,
  Input,
  Label,
  Loader,
  Modal,
  Popup,
  Tab,
  Checkbox
} from 'semantic-ui-react'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import PlaceIcon from '@mui/icons-material/Place';
import StarIcon from '@mui/icons-material/Star';
import SmsIcon from '@mui/icons-material/Sms';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import CloseIcon from '@mui/icons-material/Close';

import { getDialer } from '../../../actions/dialer'
import closeIcon1 from '../../../assets/images/closeIcon1.png'
import convertContact from '../../../assets/images/convertContact.png'
import editLead from '../../../assets/images/Dashboard-35.png'
import dotIcon from '../../../assets/images/dot.svg'
import leadIntrest from '../../../assets/images/leadInterest.png'
import leadScoreIcon from '../../../assets/images/leadScore.png'
import leadStageIcon from '../../../assets/images/leadStage.png'
import leftArrow from '../../../assets/images/left-arrow.png'
import rightArrow from '../../../assets/images/right-arrow.png'
import callNewIcon from '../../../assets/images/sms-new.png'
import tagsIcon from '../../../assets/images/tags.png'
import smsNewIcon from '../../../assets/images/telephone-new.png'
import callbackIcon from '../../../assets/images/call-back-icon.png'
import userIcon from '../../../assets/images/user.svg'
import CommonButtons from '../../../common/CommonButtons'
import { CommonNotify } from '../../../common/CommonNotify'
import ModalEverGreen from '../../../common/EverGreenModal'
import EverGreenSelect from '../../../common/EvergreenSelect'
import SendMessageModal from '../../../common/SendMessageModal'
import UpdateLeadModal from '../../../common/UpdateLeadModal'
import {
  onChangeOwner,
  onLeadScoreHandler,
  onLeadStageHandler,
  onStatusChangeHandler3
} from '../../../config/leadAPI'
import { useGetLeadQuery } from '../../../features/api/apiSlice'
import AddTagPopup from './AddTagPopup'
import LeadAboutRight from './leadAbout'
import LeadActivities from './leadActivity'
import LeadAssociate from './leadAssociate'
import LeadInsight from './leadInsight'
import TimeLine from './TimeLine'
import CallbackModal from '../../../common/CallbackModal'
import RejectPopup from './RejectPopup'
import BlockIcon from '@mui/icons-material/Block'
import countries from '../../../lib/Country'
import qualify from '../../../assets/images/qualify.png'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import FavoriteIcon from '@mui/icons-material/Favorite';
import IosShareIcon from '@mui/icons-material/IosShare';
import CallIcon from '@mui/icons-material/Call';
 import unlike from '../../../assets/images/unlike.png'
import spamImg from '../../../assets/images/spam-main.png'
import { Menu, MenuItem, Tooltip } from '@mui/material'
import LeadAnalytics from './LeadAnalytics'
import LeadFeedback from './LeadFeedback'
import LeadContact from './LeadContact'
import LeadCallLogs from './LeadCallLogs'
import CallLogs from '../../../containers/CallLogs'
import { getTeamMember } from '../../../actions/settings'

const statusOption = [
  {
    key: 'Awaiting Review',
    text: <><i style={{ color: 'orange' }} className="fa fa-hourglass-half mr-3" ></i>Pending</>,
    value: 1
  },


  {
    key: 'Reviewed', text: <><i style={{ color: 'green' }} class="fas fa-user-check mr-3"></i>Assigned </>, value: 2
  },
  {
    key: 'Disqualified',
    text: <> <i style={{ color: 'red' }} class="fas fa-phone-slash mr-3"></i>Closed </>,
    value: 3
  },

]

const leadType = [
  'Message',
  'Chat',
  'Email',
  'Web Call',
  'Phone Call',
  'WhatsApp',
  'Other',
  'Web Form',
  'Call'
]

const callDataDefault = {
  phone_number: '',
  customer_name: '',
  email: '',
  ip_address: '',
  company_domain: '',
  duration: 0,
  final_status: '',
  tags: [],
  member: {
    first_name: '',
    last_name: ''
  },
  location: {
    city: '',
    country: ''
  },
  owner: {
    temp_phone_number: '',
    company_domain: ''
  },
  source: '',
  score: 0,
  id: '',
  date: ''
}
let response = null

let updateValue = {
  sales: '',
  quotes: ''
}
const RightPane = ({
  rightPane,
  handleRightClose,
  leadOwner,
  leadScore,
  id,
  handleLoading,
  handleRightPaneOpen,
  nextLeadId,
  prevLeadId,
  leadStage,
  allTags,
  reloadTable,
  options,
  getDialer,
  setDataLoadValue,
  setLoadingValue,
  teamMembers,
  getTeamMember,
  pipeline
}) => {
  const history = useHistory()
  const [lScores, setLScores] = useState([])
  const [tagsUpdated, setTagsUpdated] = useState(false)
  const [blockModal, setBlockModal] = useState(false)
  const [dataLoaded, setDataLoaded] = useState(false)
  const [leadData, setLeadData] = useState({})
  const [leadAbout, setLeadAbout] = useState({})
  const [leadDetail, setLeadDetail] = useState({})
  const [deleteModal, setDeleteModal] = useState(false)
  const [shareModal, setShareModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [collaboratesData, setCollaboratesData] = useState([])
  const [defaultCollaborator, setDefaultCollaborator] = useState('')
  const [leadInterest, setLeadInterest] = useState(1)
  const [dropdownValue, setDropdownValue] = useState(null)
  const [addContactModal, setAddContactModal] = useState(false)
  const [openLeadModal, setOpenLeadModal] = useState(false)
  const [buttonLoader, setButtonLoader] = useState(false)
  const [callData, setCallData] = useState({ ...callDataDefault })
  const [date, setDate] = useState(null)
  const [sendModal, setSendModal] = useState(false)
  const [callBackModal, setCallBackModal] = useState(false)
  const [rejectModal, setRejectModal] = useState(false)
  const [dropDownData, setDropDownData] = useState([])
  const [funnel, setFunnel] = useState(null)
  const [stagesOptions, setStagesOptions] = useState([])
  const [selectedStage, setSelectedStage] = useState(null)
  const [menuTar, setMenuTar] = useState(null)
  
  useEffect(() => {
    if ((callBackModal || sendModal) && !dropDownData?.length) {
      getUserId()
    }
  }, [sendModal, callBackModal])

  useEffect(() => {
    if (+nextLeadId !== 0 && rightPane) {
      handleReGetValue()
    }
  }, [nextLeadId, rightPane])

  const handleReGetValue = () => {
    if (id) {
      handleLoading(true)
      fetchCountryData()
      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/lead/${id}`
      axios
        .get(url)
        .then(res => {
          response = res
          setDataLoadValue && setDataLoadValue()
          setLoadingValue && setLoadingValue()
          handleLoading(false)
          setLeadData(res.data)
          setLeadAbout(res.data.lead)
          setLeadInterest(res.data.lead.interseted)
          setLeadDetail({
            phone: res.data.lead.phone_number,
            email: res.data.lead.email
          })
          setMenuTar(null)
          setDataLoaded(true)
          setRefresh(true)
          setIsLoading(false)
          const v1 = res?.data?.lead || {}
          const v2 = res?.data || {}
          setCallData({
            ...callData,
            v1,
            v2
          })
        })
        .catch(err => {
          setDataLoadValue && setDataLoadValue()
          setLoadingValue && setLoadingValue()
          handleLoading(false)
        })
    }
  }
  let rightPanes = [
    {
      menuItem: `About`,
      render: ({ leadData, stateOptions, leadOwner, leadScore }) => (
        <>
        <LeadContact {...{ leadData, stateOptions, leadOwner, leadScore, handleReGetValue, teamMembers: collaboratesData }}/>
        
        <LeadAboutRight
          leadData={leadData}
          stateOptions={stateOptions}
          leadOwner={leadOwner}
          leadScore={leadScore}
          handleReGetValue={handleReGetValue}
          leadAbout={leadAbout} changesInterested={changesInterested} isSpan={isSpan} qualify={qualify}


          leadInterest={leadInterest} handleItemClick={handleItemClick} setRejectModal={setRejectModal}
        />
        </>
      )
    },
    // {
    //   menuItem: `Contact`,
    //   render: ({ leadData, stateOptions, leadOwner, leadScore }) => (
    //     <LeadContact {...{ leadData, stateOptions, leadOwner, leadScore, handleReGetValue, teamMembers: collaboratesData }}/>
    //   )
    // },
    {
      menuItem: 'Notes',
      render: ({ leadData, leadScore }) => (
        <LeadAssociate leadData={leadData} leadScore={leadScore} teamMembers={collaboratesData} />
      )
    },
    {
      menuItem: 'Insights',
      render: ({ leadData, leadScore }) => (
        <LeadInsight leadData={leadData} leadScore={leadScore} />
      )
    },
    {
      menuItem: 'Activities',
      render: ({ leadData, leadScore }) => (
        <LeadActivities leadData={leadData} leadScore={leadScore} />
      )
    },
    {
      menuItem: 'Timeline',
      render: ({ leadData }) => <TimeLine leadData={leadData} />
    },
    {
      menuItem: 'Call log',
      render: ({ leadData }) => <CallLogs leadData={leadData} />
    },
    {
      menuItem: 'Feedback',
      render: ({ leadData }) => <LeadFeedback leadData={leadData} />
    }
  ]

  // useEffect(() => {
  //   handleLoading(res.isLoading)
  //   if (res.isSuccess) {
  //     fetchCountryData()
  //     setLeadData(res.data)

  //     setLeadAbout(res.data.lead)
  //     setLeadInterest(res.data.lead.interseted)
  //     setLeadDetail({
  //       phone: res.data.lead.phone_number,
  //       email: res.data.lead.email
  //     })
  //     setDataLoaded(true)
  //     setRefresh(true)
  //     setIsLoading(false)
  //     const v1 = res?.data?.lead || {}
  //     const v2 = res?.data || {}
  //     setCallData({
  //       ...callData,
  //       v1,
  //       v2
  //     })
  //   }
  // }, [res])

  useEffect(() => {
    getWidget()
getCollaborates()
  }, [])

  useEffect(() => {
    if (refresh) {
      setRefresh(false)
    }
  }, [refresh])

  const fetchCountryData = () => {
    const token = '7cb27f1b3b4e06'
    const ip = callData?.ip_address
    if (ip) {
      const url = `https://ipinfo.io/${ip}?token=${token}`
      axios
        .get(url)
        .then(res => {
          if (res.data) {
            let callDataValue = {
              ...callData
            }
            let location = res.data
            callDataValue.location = location
            const time = moment()
              .tz(callDataValue.location.timezone)
              .format('yyyy-MM-DD hh:mm')

            setCallData(callDataValue)
            setDate(time)
          }
        })
        .catch(error => { })
    }
  }

  const getWidget = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/widgets`
    axios
      .get(url)
      .then(res => {
        const setInterestedData = res.data.data[0].interested
        const updateLeadInterest = res.data.data[0].interested.map(
          (item, index) => {
            return {
              key: index,
              text: item,
              value: item
            }
          }
        )
        setDropdownValue({
          interestData: [...updateLeadInterest],
          interestedData: [...setInterestedData]
        })
      })
      .catch(err => { })
  }

  const [tags, setTags] = useState([])

  const deleteModalView = async bool => {
    setDeleteModal(bool)
  }

  const shareModalView = async bool => {
    setShareModal(bool)
  }
  const getCollaborates = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/members`
    const user = []
    axios
      .get(url)
      .then(res => {
        var defaultIds = []
        var moderateArray = []

        const admin = res.data.data.admin

        moderateArray.push({
          key: admin.id,
          text: `${admin.first_name} ${admin.last_name}`,
          value: admin.id,
          label: `${admin.first_name} ${admin.last_name}`,
          image: {
            avatar: true,
            src: require('../../../assets/images/new-collab.png')
          }
        })

        res.data.data.members &&
          res.data.data.members.map(item => {
            user.push({
              _id: item.id,
              name: { first: item.first_name, last: item.last_name }
            })
            defaultIds.push(item.id)
            const d = {
              key: item.id,
              text: `${item.first_name} ${item.last_name}`,
              value: item.id,
              label: `${item.first_name} ${item.last_name}`,
              image: {
                avatar: true,
                src: require('../../../assets/images/new-collab.png')
              }
            }
            moderateArray.push(d)
          })

        const finalCollabList = moderateArray
        setCollaboratesData(finalCollabList)
      })
      .catch(err => { })
  }

  const onDeleteLeadHandler = async () => {
    setIsLoading(true)
    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/delete-leads`
    var postId = [leadAbout.id]

    const postData = {
      ids: postId
    }

    axios
      .post(URL, postData)
      .then(res => {
        setIsLoading(false)
        CommonNotify('Lead Deleted Successfully', 'success')

        deleteModalView(false)
        handleRightClose()
        reloadTable()
      })
      .catch(err => {
        setIsLoading(false)
        CommonNotify('Not able to Delete Lead')
      })
  }

  useEffect(() => {
    updateLeadScore()
  }, [leadScore?.length])

  useEffect(() => {
    if (!tagsUpdated) return
    if (!tags?.length) return

    saveTags()
  }, [tags])

  useEffect(() => {
    if (!allTags?.length) return
    fetchTags(leadAbout.tags)
  }, [leadAbout,allTags])

  const updateLeadScore = () => {
    let lScore = [...leadScore]

    if (!lScore?.length) return
    if (!Number.isInteger(lScore[lScore?.length - 1].key)) {
      lScore.splice(lScore?.length - 1, 1)
    }
    setLScores(lScore)
  }

  const handleItemClick = async (e, { name }, interseted) => {
    const oldValue = leadInterest

    setLeadInterest(interseted)

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/change-interested`
    const postData = {
      lead_id: id,
      interested: interseted
    }

    axios
      .post(url, postData)
      .then(res => {
        handleReGetValue()
        CommonNotify('Done successfully', 'success')
      })
      .catch(err => {
        setLeadInterest(oldValue)
        CommonNotify('Not able to update  Good Fit')
      })
  }

  function getDate() {
    var today = new Date()
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = today.getFullYear()
    let date = mm + '/' + dd + '/' + yyyy
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month?.length < 2) month = '0' + month
    if (day?.length < 2) day = '0' + day

    let ddd = [year, month, day].join('-')
    return ddd + ' ' + today.getHours() + ':' + (today.getMinutes() + 5)
  }

  const saveTags = () => {
    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/add-tags`

    const pTags = tags.map(tag => {
      return tag.tag
    })

    const postData = {
      tags: pTags,
      lead_id: leadAbout?.id
    }

    axios
      .post(URL, postData)
      .then(res => { })
      .catch(err => {
        CommonNotify('Not able to set tags')
      })
  }

  const fetchTags = (tags) => {
    let tgs = tags || []
    const tg = tgs || []

    const ss =tg?.forEach((item, index) => {
      if (!getTag(item)) {
        tgs.splice(index, 1)
      }
    })

    const newTags = tgs?.map(item => {
      return getTag(item)
    })

    setTags(newTags)
  }

  const getTag = tag => {
    let selectedTag = -1
    allTags.forEach((item, index) => {
      if (item.tag.toLowerCase() === tag.toLowerCase()) {
        selectedTag = index
      }
    })
    if (selectedTag == -1) return false
    return allTags[selectedTag]
  }

  const removeTag = tag => {
    let selectedTag = -1
    const selTags = [...tags]
    selTags.forEach((item, index) => {
      if (item.tag.toLowerCase() === tag.toLowerCase()) {
        selectedTag = index
      }
    })
    selTags.splice(selectedTag, 1)
    setTags(selTags)
    setTagsUpdated(true)
  }

  const openLead = () => {
    history.push(`/leads/${leadAbout.id}`)
  }

  const addTag = (tag, index) => {
    let avilable = false

    tags.forEach(tg => {
      if (tg.tag.toLowerCase() === tag.toLowerCase()) {
        avilable = true
      }
    })

    if (avilable) return
    let newTags = [...tags]
    newTags.push(allTags[index])
    setTags(newTags)
    setTagsUpdated(true)
  }

  const toggleFLead = async (id, fav, spanFlag) => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${id}/flags`
    if (fav) {
      axios
        .post(url, {
          flag: spanFlag || 1
        })
        .then(res => {
          if (res.data.message[0] == 'Successfully') {
            reloadTable()
            handleReGetValue()
          }
        })
        .catch(error => {
          CommonNotify(error.response.data.errors[0], 'warning')
        })
    } else {
      axios
        .delete(url, {
          data: {
            flag: spanFlag || 1
          }
        })
        .then(res => {
          if (res.data.message[0] == 'Successfully') {
            reloadTable()
            handleReGetValue()
          }
        })
        .catch(error => {
          CommonNotify(error.response.data.errors[0], 'warning')
        })
    }
  }

  const isFLead = () => {
    if (leadAbout?.is_fav?.toString() === '1') {
      return true
    } else {
      return false
    }
  }
  const isSpan = () => {
    if (leadAbout?.is_spam?.toString() === '1') {
      return true
    } else {
      return false
    }
  }

  const isDuplicate = () => {
    if (leadAbout?.duplicate?.toString() === '1') {
      return true
    } else {
      return false
    }
  }

  const isRepeat = () => {
    if (leadAbout?.repeated?.toString() === '1') {
      return true
    } else {
      return false
    }
  }

  const onUpdateInterest = async data => {
    if (!data || !id) {
      return
    }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${id}/info`
    const postData = {
      lead_interest: data
    }
    axios
      .post(url, postData)
      .then(res => {
        CommonNotify('Lead Interest updated successfully.', 'success')
      })
      .catch(err => {
        CommonNotify('Lead Interest not updated successfully.', 'warning')
      })
  }

  const onCollaboratorsHandler = async data => {
    const lead_Id = leadAbout.id
    const value = data
    if (!value) {
      return
    }

    var filtered = value?.filter(function (e) {
      return this.indexOf(e) < 0
    }, defaultCollaborator)

    let stringParams = value?.map((item, index) => {
      return `&to[${index}]=${item}`
    })

    const user_id = await localStorage.getItem('id')

    var url = ''
    if (stringParams?.length >= 1) {
      stringParams = stringParams.join('')

      url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/mention-member?from=${user_id}&content=${lead_Id}${stringParams}`
    } else {
      url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/mention-member?from=${user_id}&content=${lead_Id}`
    }

    const postData = {}
    axios
      .post(url, postData)
      .then(res => {
        CommonNotify('Lead collaborator saved ', 'success')
      })
      .catch(err => {
        CommonNotify('Some error occured.')
      })
  }
  
  
  const onChangePipeline = async (value, instance) => {
    if (instance === 'funnel') {

      const ff = pipeline?.filter((obj) => obj?.name === value)?.[0]
      setFunnel(ff)
      setStagesOptions(ff?.stages)
      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${leadAbout?.id}/funnel`

      await axios
        .put(url, { funnel_id: ff?.id || null, stage: ff?.id ? leadAbout?.stage || selectedStage : null })
        .then(res => {

          CommonNotify('Updated Successfully', 'sucess')
        })
        .catch(error => { })

    } else {
      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${leadAbout?.id}/funnel`
      setSelectedStage(value)
      await axios
        .put(url, { funnel_id: funnel?.id, stage: value })
        .then(res => {
          CommonNotify('Updated Successfully', 'sucess')


        })
        .catch(error => { })
    }

  }
  
  useEffect(() => {
    setFunnel(leadAbout?.funnel)
    const stageOption = pipeline?.filter((obj) => obj?.id === leadAbout?.funnel?.id)?.[0]?.stages
    setStagesOptions(stageOption)
    setSelectedStage(leadAbout?.stage)
  }, [leadAbout])

  const onUpdateRequest = async data => {
    if (id) {
      if (data.phone_number) {
        if (data.phone_number?.length < 10) {
          return CommonNotify(
            'Validation error invalid phone number',
            'warning'
          )
        }
      }
      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${id}/info`
      const postData = {
        customer_name: data.customer_name,
        phone_number: data.phone_number,
        email: data.email,
        notes: data.interest,
        country: data.country,
        role: data.role,
        company_name: data.company,
        industry: data.industry,
        employees: data.companySize,
        lead_interest: data.lead_interest
      }
      axios
        .post(url, postData)
        .then(res => {
          CommonNotify('Lead info updated', 'success')
          setOpenLeadModal(false)
          // useGetLeadQuery(id)
        })
        .catch(err => {
          CommonNotify('Cant Update Lead Detail')
        })
    }
  }

  const addContactLeadHandler = async () => {
    setButtonLoader(true)
    setAddContactModal(false)
    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${id}/contact`
    axios
      .post(URL, {})
      .then(res => {
        let cloneCallData = { ...callData }
        cloneCallData.contact = res.data.data || {}
        setCallData(cloneCallData)
        // this.setState({ isLoading: false, buttonLoader: false, callData })
        setButtonLoader(false)
        CommonNotify('Contact added Successfully', 'success')
      })
      .catch(err => {
        setButtonLoader(false)
        CommonNotify(
          err?.response?.data?.errors?.[0] || 'Not able to add Contact'
        )
      })
  }

  const getUserId = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/caller-id`
    axios
      .get(url)
      .then(res => {
        let data = []
        data = res.data.data.map(number => {
          return {
            key: number[0],
            value: number[0],
            text: `LimeCall Number (${number[0]})`
          }
        })
        setDropDownData(data || [])
      })
      .catch(err => { })
  }
  const changesInterested = async (value) => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/change-interested`
    axios
      .post(url, { interested: value, lead_id: leadAbout.id })
      .then(res => {
        handleReGetValue()
      })

      .catch(err => { })
  }
  const changesSpam = async (value) => {

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${leadAbout.id}/flags`
    axios
      .post(url, { flag: value })
      .then(res => {
        handleReGetValue()
      })

      .catch(err => { })
  }

  const handleCloseModal = () => {
    setTimeout(() => {
      setSendModal(false)
    }, 200)
  }
  const typeOptions = [
    { key: 1, text: 'Callback', value: 'LIVE_CALL' },
    { key: 2, text: 'Missed Call', value: 'MISSED_CALL' },
    { key: 3, text: 'Message', value: 'LEAVE_MESSAGE' },
    { key: 4, text: 'SMS', value: 'SMS' },
    { key: 5, text: 'Scheduled Call', value: 'SCHEDULE_CALL' },
    { key: 6, text: 'Lead', value: 'LEAD' },
    { key: 7, text: 'Web Call', value: 'DIGITAL_CALL' },
    { key: 8, text: 'Inbound via Virtual Number', value: 'FORWARDED_CALL' }
  ]
  const handleClick = () => {
    if (collaboratesData && !collaboratesData?.length) {
      getCollaborates()
    }
  }

  const handleSave = (e, name) => {
    handleItemClick(e, { name }, 3)
  }

  const handleBlock = value => {
    const code = countries?.find(
      ({ name }) =>
        name?.toLocaleLowerCase() === leadData?.ip_country?.toLocaleLowerCase()
    )?.code
    if (value && code) {
      const data = {
        block_reason: 'spammer',
        widget_id: leadAbout?.widget_id || localStorage.getItem('widget_id'),
        phone_number: value,
        phone_country: code
      }
      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/blacklist-user`
      axios
        .post(url, data)
        .then(res => {
          CommonNotify('User Contact Added to BlockList', 'success')
        })
        .catch(error => {
          CommonNotify(error?.response?.data?.errors?.[0], 'warning')
        })
    } else {
      if (!code) {
        CommonNotify(
          "Country code is not available in lead info so, We can't block this number"
        )
        return
      }
      CommonNotify(
        "Contact number is not available so, We can't block this number"
      )
    }
  }

  const UpdateLeadTypes = (value, id) => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${id}/fields`
    axios
      .put(url, value)
      .then(res => {
        reloadTable()
        handleReGetValue()
        CommonNotify('Lead updated successfully', 'success')
      })
      .catch(error => {
        CommonNotify(error?.response?.data?.errors?.[0], 'warning')
      })
  }

  return dataLoaded ? (
    <div>
      <Dimmer active={isLoading} style={{ position: 'fixed' }}>
        <Loader />
      </Dimmer>
      <div
        style={{ zIndex: '100 !important' }}
        className={rightPane ? 'callModel rightPopUp' : 'callModel'}
      >
        <div className="action-icons">
          <img src={closeIcon1} onClick={handleRightClose} />
          <img
            src={leftArrow}
            onClick={() => handleRightPaneOpen(prevLeadId)}
          />
          <img
            src={rightArrow}
            onClick={() => handleRightPaneOpen(nextLeadId)}
          />
        </div>
        <div className="detail-title">
          <div
            className="titleCall"
            style={{
              justifyContent: 'flex-end',
              paddingBottom: '8px',
              borderBottom: '1px solid #0066e8'
            }}
          >
            <div style={{ display: 'flex' }}>
              <div
                className="detail-title-icon"
                style={{ padding: '1px 3px 2px', display: 'flex' }}
              >
                {/* <h5>Lead Detail - ( {leadAbout?.id} )</h5> */}
              </div>
            </div>
            <div>
              <CommonButtons
                style={{ display: 'block' }}
                content="View profile"
                background="blue"
                btnClass="btn-verify"
                onClick={openLead}
              />
            </div>
            <div
              className="lead_page"
              style={{
                backgroundColor: 'transparent',
                padding: '0px 12px'
              }}
            >
              <div className="main_wrapper">
                <div className="good-fit-menu middle-container">
                  <div
                    className="middle-content"
                    style={{ margin: 'auto', fontWeight: 600 }}
                  >
                    {
                      typeOptions?.filter(
                        obj => obj?.value === leadAbout?.type
                      )?.[0]?.text
                    }
                  </div>

                  {/* <Label
                    style={{
                      fontWeight: 300,
                      color: 'black',
                      backgroundColor: '#ffffff',
                      borderColor: '#9a9393',
                      marginRight: 20
                    }}
                  >
                    Qualified?
                  </Label> */}

                  {/* <Menu
                    className="middle-content"
                    style={{ marginBottom: 0, marginTop: 0 }}
                  >
                    <Popup
                      content="Mark as Qualified"
                      trigger={
                        <Menu.Item
                          name="1"
                          active={leadInterest == '1'}
                          onClick={(e, name) => handleItemClick(e, { name }, 1)}
                        >
                          <i className="fa fa-check"></i>
                        </Menu.Item>
                      }
                    />

                    <Popup
                      content="Not Reviewed"
                      trigger={
                        <Menu.Item
                          name="2"
                          active={leadInterest == '2'}
                          onClick={(e, name) => handleItemClick(e, { name }, 2)}
                        >
                          <i className="fa fa-question"></i>
                        </Menu.Item>
                      }
                    />
                    <Popup
                      content="Mark as Not Qualified"
                      trigger={
                        <Menu.Item
                          name="3"
                          active={leadInterest == '3'}
                          onClick={
                            // (e, name) => handleItemClick(e, { name }, 3)
                            () => setRejectModal(true)
                          }
                        >
                          <i className="fa fa-times"></i>
                        </Menu.Item>
                      }
                    />
                  </Menu> */}
                  <div
                    className="callDetail middle-content"
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Tooltip title="Stage">
                      <div style={{ margin: 'auto' }}>
                        <Dropdown
                          className="card-boxes-right-text"
                          options={[
                            {
                              key: 0,
                              text: 'Unassign',
                              value: ''
                            },
                            ...pipeline?.map((obj, index) => ({
                              key: index + 1,
                              text: obj?.name,
                              value: obj?.name
                            }))
                          ]}
                          placeholder="Add to Pipeline"
                          defaultValue={funnel?.name}
                          value={funnel?.name}
                          onChange={(e, dat) =>
                            onChangePipeline(dat.value, 'funnel')
                          }
                        />
                      </div>
                    </Tooltip>
                  </div>
                  {funnel && (
                    <div
                      className="callDetail middle-content"
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Tooltip title="Stage">
                        <div style={{ margin: 'auto' }}>
                          <Dropdown
                            className="card-boxes-right-text"
                            options={[
                              {
                                key: 0,
                                text: 'Unassign',
                                value: ''
                              },
                              ...stagesOptions?.map((item, index) => ({
                                key: index,
                                text: item,
                                value: item
                              }))
                            ]}
                            placeholder="Set lead stage"
                            defaultValue={selectedStage}
                            value={selectedStage}
                            onChange={(e, dat) => onChangePipeline(dat.value)}
                          />
                        </div>
                      </Tooltip>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="detail-title-icon" style={{ textAlign: 'center' }}>
              <div className="favorite-section" style={{ display: 'flex' }}>
                <Tooltip title="Mark as spam">
                  <button
                    className={isSpan() ? 'active common-btn ' : 'common-btn'}
                    onClick={e => {
                      changesSpam(2)
                    }}
                  >
                    <img
                      src={spamImg}
                      className="mr-2"
                      style={{ color: '#FFA500' }}
                    />
                  </button>
                </Tooltip>

                <Tooltip title="Delete Lead">
                  <button
                    onClick={e => {
                      deleteModalView(true)
                    }}
                    className="common-btn"
                  >
                    <RemoveCircleIcon style={{ color: 'red' }} />
                  </button>
                </Tooltip>

                <Tooltip title="Add to Favourite">
                  <button
                    className={isFLead() ? 'active common-btn ' : 'common-btn'}
                    onClick={e => {
                      toggleFLead(
                        leadAbout.id,
                        isFLead() === true ? false : true,
                        1
                      )
                    }}
                  >
                    <StarIcon />
                  </button>
                </Tooltip>
                <Tooltip title="Share">
                  <button
                    className={isFLead() ? 'active common-btn ' : 'common-btn'}
                    onClick={onCollaboratorsHandler}
                  >
                    <IosShareIcon />
                  </button>
                </Tooltip>
                {/* <Popup
                  content="Collaboration"
                  mouseEnterDelay={500}
                  onOpen={handleClick}
                  trigger={
                    <div className="tags_set">
                      <EverGreenSelect
                        defaultValues={defaultCollaborator}
                        head="Share"
                        type="members"
                        title={<><IosShareIcon /></>}
                        onlyTitle
                        allOptions={collaboratesData}
                        onUpdateValues={onCollaboratorsHandler}
                      ></EverGreenSelect>
                    </div>
                  }
                /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="aboutSubpopup">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="detail-popup-profile">
              {!refresh && (
                <div
                  className="detailHeadProfile"
                  style={{ overflow: 'auto', height: 'calc(100vh - 84px' }}
                >
                  <div className="popUpHead">
                    <div className="edit-profile-icon">
                      {/* {!leadAbout.contact ? (
                        <a
                          className="mail_set col px-1"
                          onClick={e => setOpenLeadModal(true)}
                        >
                          <img src={editLead} />
                        </a>
                      ) : null} */}
                      {!leadAbout.contact ? (
                        <a
                          className="mail_set px-1"
                          onClick={e => setAddContactModal(true)}
                        >
                          <img style={{ width: 22 }} src={convertContact} />
                        </a>
                      ) : null}
                    </div>
                    {leadAbout?.enrichment?.company_logo && (
                      <div className="user-image">
                        <img
                          src={leadAbout?.enrichment?.company_logo}
                          alt="logo"
                        />
                      </div>
                    )}
                    <div className="user-info">
                      <h5>{leadAbout?.customer_name || ''} </h5>
                      <p
                        style={{ marginBottom: '3px' }}
                        title="Block Contact Number"
                      >
                        {leadDetail.phone && (
                          <BlockIcon
                            className="cursor-pointer mr-2"
                            onClick={() => handleBlock(leadDetail.phone)}
                          />
                        )}{' '}
                        {leadDetail.phone || ''}
                      </p>
                      {/* {res?.data?.ip_country && <p>{res?.data?.ip_country}</p>} */}
                      {leadAbout?.role && <p>{leadAbout?.role}</p>}
                      {leadAbout?.enrichment && (
                        <p style={{ marginBottom: '3px' }}>
                          {leadAbout?.enrichment.company_name || ''}
                        </p>
                      )}
                      {/* {leadAbout?.country && (
                        <img
                          width="10%"
                          src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${leadAbout?.country.toUpperCase()}.svg`}
                        />
                      )}{' '} */}
                      {leadData.ip_country ? (
                        <p>
                          <PlaceIcon
                            style={{ width: '20px', height: '20px' }}
                          />
                          {leadData.ip_country}
                        </p>
                      ) : null}
                      <div className="d-flex align-items-center justify-content-center mb-3">
                        <div
                          className="detail-title-icon"
                          style={{ textAlign: 'center' }}
                        >
                          <div
                            className="favorite-section"
                            style={{ display: 'flex' }}
                          >
                            {leadAbout?.phone_number && (
                              <>
                                <Tooltip
                                  title={`Call back ${leadAbout?.customer_name}`}
                                >
                                  <button
                                    onClick={() => {
                                      setTimeout(() => {
                                        setCallBackModal(true)
                                      }, 300)
                                    }}
                                    className="common-btn"
                                  >
                                    <PhoneCallbackIcon />
                                  </button>
                                </Tooltip>

                                {callBackModal && (
                                  <CallbackModal
                                    isModalClose={() => {
                                      setTimeout(() => {
                                        setCallBackModal(false)
                                      }, 300)
                                    }}
                                    isModalOpen={() => console.log(`open`)}
                                    open={callBackModal}
                                    dropDownData={dropDownData || []}
                                    leadData={leadAbout}
                                    phone_numbber={leadAbout?.phone_number}
                                  />
                                )}
                              </>
                            )}
                            {leadAbout?.phone_number && (
                              <Tooltip
                                title={`Call ${leadAbout?.customer_name}`}
                              >
                                <button
                                  onClick={e =>
                                    getDialer(leadAbout?.phone_number)
                                  }
                                  className="common-btn"
                                >
                                  <CallIcon />
                                </button>
                              </Tooltip>
                            )}
                            {leadAbout?.phone_number && (
                              <>
                                <Tooltip
                                  title={`Sms ${leadAbout?.customer_name}`}
                                >
                                  <button
                                    onClick={() => {
                                      setTimeout(() => {
                                        setCallBackModal(true)
                                      }, 300)
                                    }}
                                    className="common-btn"
                                  >
                                    <SmsIcon />
                                  </button>
                                </Tooltip>

                                {sendModal && (
                                  <SendMessageModal
                                    isModalClose={() => handleCloseModal()}
                                    isModalOpen={() => console.log(`open`)}
                                    open={sendModal}
                                    dropDownData={dropDownData || []}
                                    leadData={leadAbout}
                                    phone_numbber={leadAbout?.phone_number}
                                  />
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="user-acc-info">
                      <div className="acc-info-box">
                        <div className="acc-inner-box">
                          <span>Answered</span>
                          <h2>{leadAbout?.total_answered || 0}</h2>
                        </div>
                      </div>

                      <div className="acc-info-box">
                        <div className="acc-inner-box">
                          <span>Outgoing</span>
                          <h2>{leadAbout?.total_outgoing_calls || 0}</h2>
                        </div>
                      </div>
                      <div className="acc-info-box">
                        <div className="acc-inner-box">
                          <span>Leads</span>
                          <h2>{leadAbout?.total_leads || 0}</h2>
                        </div>
                      </div>
                      <div className="acc-info-box">
                        <div className="acc-inner-box">
                          <span>Missed</span>
                          <h2>{leadAbout?.total_missed_calls || 0}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="popUpDetail">
                    <div className="callDetail">
                      <div className="Iconlabel">
                        <img alt="" src={userIcon} />
                        <label
                          style={{
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: '14px'
                          }}
                        >
                          Owner
                        </label>
                      </div>
                      <Tooltip title="Owner">
                        <div>
                          <Dropdown
                            className="card-boxes-right-text"
                            options={leadOwner}
                            placeholder="Assign Owner"
                            basic
                            defaultValue={
                              leadAbout?.owner_id === null
                                ? leadAbout?.interseted
                                : leadAbout?.owner_id
                            }
                            onChange={(e, dat) =>
                              onChangeOwner(e, dat, leadAbout?.id)
                            }
                          />
                        </div>
                      </Tooltip>
                    </div>
                    <div className="callDetail">
                      <div className="Iconlabel">
                        <img alt="" src={leadScoreIcon} />
                        <label
                          style={{
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: '14px'
                          }}
                        >
                          Score
                        </label>
                      </div>

                      <Tooltip title="Lead Score">
                        <div>
                          <Dropdown
                            className="card-boxes-right-text"
                            placeholder="Add Score"
                            options={lScores}
                            defaultValue={leadAbout?.score}
                            onChange={(e, dat) =>
                              onLeadScoreHandler(e, dat, leadAbout.id)
                            }
                          />
                        </div>
                      </Tooltip>
                    </div>
                    <div className="callDetail">
                      <div className="Iconlabel">
                        <img alt="ints" src={leadIntrest} />
                        <label
                          style={{
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: '14px'
                          }}
                        >
                          Interested in
                        </label>
                      </div>

                      <Tooltip title="Lead Interest">
                        <div>
                          <Dropdown
                            className="card-boxes-right-text"
                            placeholder="Add Interest"
                            options={dropdownValue?.['interestData'] || []}
                            defaultValue={leadAbout?.lead_interest || null}
                            onChange={(e, dat) => {
                              onUpdateInterest(dat?.value)
                            }}
                          />
                        </div>
                      </Tooltip>
                    </div>

                    <div className="callDetail">
                      <div className="Iconlabel">
                        <img alt="ints" src={leadIntrest} />
                        <label
                          style={{
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: '14px'
                          }}
                        >
                          Status
                        </label>
                      </div>

                      <Tooltip title="Lead Status">
                        <div>
                          <Dropdown
                            className="card-boxes-right-text"
                            placeholder="Lead Status"
                            options={statusOption}
                            defaultValue={parseInt(leadAbout?.status)}
                            onChange={(e, dat) =>
                              onStatusChangeHandler3(e, dat, leadAbout.id)
                            }
                          />
                        </div>
                      </Tooltip>
                    </div>

                    <div className="callDetail">
                      <div className="Iconlabel">
                        <img alt="tags" src={tagsIcon} />
                        <label
                          style={{
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: '14px'
                          }}
                        >
                          Tags
                        </label>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          maxWidth: '50%',
                          flexWrap: 'wrap',
                          gap: '8px'
                        }}
                      >
                        {tags.map(data => (
                          <span
                            style={{
                              backgroundColor: data.color,
                              padding: '4px 10px',
                              height: '30px',
                              color: '#fff',
                              borderRadius: '3px',
                              marginRight: '5px',
                              marginTop: '0'
                            }}
                          >
                            {' '}
                            {data.tag}{' '}
                            <CloseIcon onClick={() => removeTag(data.tag)} />
                          </span>
                        ))}
                        {/* <Tooltip title='Tags'> */}
                        <div>
                          <Popup
                            className="profile_popup"
                            content={
                              <AddTagPopup tags={allTags} addTag={addTag} />
                            }
                            size="large"
                            position="bottom center"
                            flowing
                            on="click"
                            trigger={
                              <div style={{ marginTop: '15px' }}>
                                {/* {!tags?.length && ( */}
                                <a href="javascript:void(0)"> + Add New Tag</a>
                                {/* )}{' '} */}
                              </div>
                            }
                          />
                        </div>
                        {/* </Tooltip> */}
                      </div>
                    </div>

                    <div className="callDetail">
                      <div className="Iconlabel">
                        <img alt="" src={leadStageIcon} />
                        <label
                          style={{
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: '14px'
                          }}
                        >
                          Lead Type
                        </label>
                      </div>
                      <Dropdown
                        className="card-boxes-right-text"
                        placeholder="Select type"
                        defaultValue={leadAbout.lead_type || ''}
                        onChange={(e, dat) =>
                          UpdateLeadTypes(
                            { lead_type: dat?.value },
                            leadAbout.id
                          )
                        }
                        options={leadType?.map(option => ({
                          key: option,
                          text: option,
                          value: option?.toLocaleLowerCase()
                        }))}
                      />
                    </div>

                    <div className="callDetail">
                      <div className="Iconlabel">
                        <img alt="" src={leadStageIcon} />
                        <label
                          style={{
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: '14px'
                          }}
                        >
                          Sales Value($)
                        </label>
                      </div>

                      <Input
                        style={{ height: '30px', width: '50%' }}
                        type="number"
                        min="0"
                        className="card-boxes-right-text"
                        defaultValue={leadAbout?.sales_value || ''}
                        placeholder="$"
                        id="sales-value"
                        onChange={e => {
                          updateValue.sales = e?.target?.value
                        }}
                        onBlur={(e, dat) =>
                          UpdateLeadTypes(
                            { sales_value: updateValue.sales },
                            leadAbout.id
                          )
                        }
                      />
                    </div>

                    <div className="callDetail">
                      <div className="Iconlabel">
                        <img alt="" src={leadStageIcon} />
                        <label
                          style={{
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: '14px'
                          }}
                        >
                          Quote Value($)
                        </label>
                      </div>

                      <Input
                        className="card-boxes-right-text"
                        style={{ height: '30px', width: '50%' }}
                        onChange={e => {
                          updateValue.quotes = e?.target?.value
                        }}
                        defaultValue={leadAbout?.quote_value || ''}
                        placeholder="$"
                        type="number"
                        min="0"
                        id="quote-value"
                        onBlur={() =>
                          UpdateLeadTypes(
                            { quote_value: updateValue.quotes },
                            leadAbout.id
                          )
                        }
                      />
                    </div>

                    <div className="callDetail">
                      <div className="Iconlabel">
                        <img alt="" src={leadStageIcon} />
                        <label
                          style={{
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: '14px'
                          }}
                        >
                          Duplicate
                        </label>
                      </div>

                      <Checkbox
                        defaultChecked={
                          +leadAbout?.duplicate === 0 ? false : true
                        }
                        toggle
                        onChange={(e, data) => {
                          toggleFLead(
                            leadAbout.id,
                            isDuplicate() === true ? false : true,
                            8
                          )
                        }}
                      />
                    </div>

                    <div className="callDetail">
                      <div className="Iconlabel">
                        <img alt="" src={leadStageIcon} />
                        <label
                          style={{
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: '14px'
                          }}
                        >
                          Repeat Lead
                        </label>
                      </div>

                      <Checkbox
                        defaultChecked={
                          +leadAbout?.repeated === 0 ? false : true
                        }
                        toggle
                        onChange={(e, data) => {
                          toggleFLead(
                            leadAbout.id,
                            isRepeat() === true ? false : true,
                            16
                          )
                        }}
                      />
                    </div>
                    <div
                      className="callDetail"
                      style={{
                        display: 'flex',
                        marginTop: '30px',
                        justifyContent: 'space-evenly'
                      }}
                    >
                      {JSON.parse(localStorage.getItem('id') || '') !==
                      leadAbout?.owner?.id ? (
                        <CommonButtons
                          style={{
                            display: 'block',
                            borderRadius: '15px',
                            width: '40%'
                          }}
                          content="Claim"
                          background="blue"
                          btnClass="btn-verify"
                          onClick={e =>
                            onChangeOwner(
                              e,
                              { value: localStorage.getItem('id') },
                              leadAbout?.id,
                              handleReGetValue
                            )
                          }
                        />
                      ) : null}

                      <CommonButtons
                        style={{
                          display: 'block',
                          borderRadius: '15px',
                          width: '40%',
                          border: '1px solid #0d68c9',
                          color: '#0d68c9'
                        }}
                        onClick={event => setMenuTar(event.currentTarget)}
                        id="basic-buttonn"
                        aria-controls={menuTar ? 'basic-menun' : undefined}
                        aria-haspopup="true"
                        aria-expanded={menuTar ? 'true' : undefined}
                      >
                        Transfer
                      </CommonButtons>

                      {menuTar ? (
                        <Menu
                          id="basic-menun"
                          anchorEl={menuTar}
                          open={!!menuTar}
                          onClose={() => setMenuTar(null)}
                          MenuListProps={{
                            'aria-labelledby': 'basic-buttonn'
                          }}
                        >
                          {leadOwner?.map(obj => (
                            <MenuItem
                              onClick={e =>
                                onChangeOwner(
                                  e,
                                  { value: obj?.value },
                                  leadAbout?.id,
                                  handleReGetValue
                                )
                              }
                            >
                              {obj?.text}
                            </MenuItem>
                          ))}
                        </Menu>
                      ) : null}
                    </div>
                    <div
                      className="callDetail"
                      style={{
                        display: 'flex',
                        marginTop: '10px',
                        justifyContent: 'center',
                        gap:'15px'
                      }}
                    >
                      <>
                        {leadAbout?.interseted !== 1 && (
                          <Tooltip title="Mark as Good fit">
                            <span>
                              <ThumbUpIcon
                                onClick={(e, name) =>
                                  handleItemClick(e, { name }, 1)
                                }
                              />
                            </span>
                          </Tooltip>
                        )}

                        {leadAbout?.interseted !== 3 && (
                          <Tooltip title="Mark as Bad fit">
                            <span>
                              <ThumbDownIcon
                                onClick={() => setRejectModal(true)}
                              />
                            </span>
                          </Tooltip>
                        )}
                      </>
                    </div>

                    {/* Lead Qualification: */}
                  </div>
                </div>
              )}
            </div>

            <div className="contact-popup-detail lead-right-side-bar">
              {!refresh && (
                <Tab
                  menu={{ secondary: true, pointing: true }}
                  panes={rightPanes}
                  leadData={leadData}
                  leadOwner={leadOwner}
                  leadScore={lScores}
                />
              )}
              <ModalEverGreen
                title="Alert"
                content="Are you sure you want to block ?"
                status={blockModal}
              ></ModalEverGreen>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="local-number-modal"
        open={deleteModal}
        onClose={e => deleteModalView(false)}
      >
        <Modal.Header> Delete this lead ?</Modal.Header>
        <Modal.Content>
          <div style={{ textAlign: 'left' }} className="modal-content">
            <div style={{ padding: '25px' }}>
              <p style={{ marginTop: 20 }}>
                Please confirm if you wish to delete this lead ?
              </p>
              <ul>
                <li>Lead id: 34343</li>
                <li>All calls, logs and details will be deleted</li>
                <li>This action cannot be undone</li>
              </ul>
            </div>
            <Modal.Actions>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                  paddingTop: 10,
                  paddingBottom: 10
                }}
              >
                <CommonButtons
                  onClick={() => deleteModalView(false)}
                  type="button"
                  content="Cancel"
                  background="red"
                  style={{
                    borderRadius: 0,
                    backgroundColor: '#e0e1e2',
                    color: '#5a5a5a'
                  }}
                />

                <CommonButtons
                  onClick={onDeleteLeadHandler}
                  type="button"
                  content="Confirm"
                  background="blue"
                  style={{
                    marginLeft: 20,
                    marginRight: 20,
                    borderRadius: 0,
                    backgroundColor: '#1071eb',
                    color: '#ffffff'
                  }}
                />
              </div>
            </Modal.Actions>
          </div>
        </Modal.Content>
      </Modal>

      <Modal
        className="local-number-modal"
        open={shareModal}
        onClose={e => shareModalView(false)}
      >
        <Modal.Header className="modal-header">Share Lead </Modal.Header>
        <Modal.Content>
          <div className="modal-input-wrapper">
            <Popup
              content="Collaboration"
              mouseEnterDelay={500}
              trigger={
                <div className="tags_set">
                  <EverGreenSelect
                    defaultValues={defaultCollaborator}
                    head="Share"
                    type="members"
                    title="Share"
                    allOptions={collaboratesData}
                    onUpdateValues={onCollaboratorsHandler}
                  ></EverGreenSelect>
                </div>
              }
            />
          </div>
        </Modal.Content>
      </Modal>
      {/* {openLeadModal && (
        <UpdateLeadModal
          isModalClose={() => setOpenLeadModal(false)}
          isModalOpen={() => setOpenLeadModal(true)}
          open={openLeadModal}
          leadData={leadAbout}
          onUpdateRequest={onUpdateRequest}
          leadId={id}
          interested={
            (dropdownValue?.interestedData &&
              dropdownValue?.interestedData?.length &&
              dropdownValue?.interestedData) ||
            []
          }
          lead_interest={leadAbout?.lead_interest}
        />
      )} */}
      {addContactModal && (
        <Modal
          className="local-number-modal"
          open={addContactModal}
          onClose={() => setAddContactModal(false)}
        >
          <Modal.Content>
            <div className="modal-content">
              <p style={{ marginTop: 20 }}>
                Are you sure you want to convert this lead as contact ?
              </p>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                  paddingTop: 10,
                  paddingBottom: 10
                }}
              >
                <CommonButtons
                  onClick={() => setAddContactModal(false)}
                  type="button"
                  content="Cancel"
                  background="red"
                  style={{
                    borderRadius: 0,
                    backgroundColor: '#e0e1e2',
                    color: '#5a5a5a'
                  }}
                />

                <CommonButtons
                  loading={buttonLoader}
                  onClick={addContactLeadHandler}
                  type="button"
                  content="Confirm"
                  background="blue"
                  style={{
                    marginLeft: 20,
                    marginRight: 20,
                    borderRadius: 0,
                    backgroundColor: '#1071eb',
                    color: '#ffffff'
                  }}
                />
              </div>
            </div>
          </Modal.Content>
        </Modal>
      )}
      {rejectModal && (
        <RejectPopup {...{ response, setRejectModal, id, handleSave }} />
      )}
    </div>
  ) : (
    ''
  )
}

const mapStateToProps = state => {
  return {
    dialerStatus: state.dialer.currentStatus,
    teamMembers: state.settings?.teamMember,
    pipeline: state.home.pipeline
  }
}

export default compose(
  withRouter,
  
  connect(mapStateToProps, { getDialer, getTeamMember })
)(RightPane)
