import React, { Component } from 'react'
import axios from 'axios'
import CommonTable from '../../common/CommonTable'
import { Dimmer, Loader, Image, Segment } from 'semantic-ui-react'
// redux
import { connect } from 'react-redux'
import { getPaymentLog } from '../../actions/settings'
import noPayments from '../../assets/images/no-payments.png'


class Paymentlog extends Component {
  state = {
    dataTable: {
      type: '8',
      header: [
        {
          headerTitle: 'Date'
        },
        {
          headerTitle: 'Transaction id '
        },
        {
          headerTitle: 'Details'
        },
        {
          headerTitle: 'Amount'
        },
        {
          headerTitle: 'Payment source'
        }
      ],
      tableContentData: []
    },
    isLoading: true
  }

  componentDidUpdate(prevProps) {
    if (this.props.paymentLog !== prevProps.paymentLog) {
      const d = this.props.paymentLog.data?.data      
      const { dataTable } = this.state
      let allData = dataTable.tableContentData

      for (let index = 0; index < d?.length; index++) {
        const temp = d?.[index]?.log?.details.split('$')
        const cardNumber = d?.[index]?.billing_log?.source.substr(d?.[index]?.billing_log?.source?.length - 4)
        const cardName = d?.[index]?.billing_log?.card_name

        const result = {
          columnTwo: d[index].log.time,
          columnThree: d[index].agent_name,
          columnFour: d[index].log.details,
          columnFive: `$ ${temp[0] || 0}`,
          columnSix: `${cardName ? `${cardName} ${cardNumber}` : "-"}`,   
            action: 'edit'         
        }
        allData.push(result)
      }

      this.setState({ tableContentData: allData, isLoading: false })   
    }
  }

  componentDidMount() {
    if (this.props.paymentLog) {
      const d = this.props.paymentLog.data       

      const { dataTable } = this.state
      let allData = dataTable.tableContentData

      for (let index = 0; index < d?.length; index++) {
        const temp = d?.[index]?.log?.details.split('$')
        const cardNumber = d?.[index]?.billing_log?.source.substr(d?.[index]?.billing_log?.source?.length - 4)
        const cardName = d?.[index]?.billing_log?.card_name
        const result = {
          columnTwo: d[index].log.time,
          columnThree: d[index].agent_name,
          columnFour: d[index].log.details,
          columnFive: `$ ${temp[0] || 0}`,
          columnSix: `${cardName ? `${cardName} ${cardNumber}` : "-"}`,
        }
        allData.push(result)
      }

      this.setState({ tableContentData: allData, isLoading: false })   
    } else {
      this.setState({ isLoading: true })   
      this.props.getPaymentLog()
    }

    
    
  }

  firstLetterCap = (string) =>{
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  
  render() {
    return (
      <>
        <Dimmer active={this.state.isLoading} style={{ position: 'fixed' }}>
          <Loader />
        </Dimmer>

        <div className="d-flex justify-content-between mb-1 pb-2 mt-3">
          <h3>Payments</h3>
        </div>
        <div className="mb-3">
          <p>Your payment history for all limecall charges</p>
        </div>
        <div className="billing-invoice-margin invoices">
          {this.props.paymentLog?.data?.data?.length > 0 ? (
            <CommonTable dataTable={this.state.dataTable} type="8" />
          ) : 
           !this.state.isLoading  ? 
           <div
              style={{
                margin: 'auto',
                paddingTop: '100px'
              }}
            >
              {' '}
              <p className="text-center">
                <img
                  style={{
                    height: 100,
                    width: 'auto',
                    margin: 'auto'
                  }}
                  src={noPayments}
                  alt=""
                />
              </p>
              <p
                className="text-center"
                style={{
                  fontWeight: 600,
                  fontSize: '16px',
                  margin: 'auto'
                }}
              >
                No payment history available.
              </p>
            </div>
          : null}
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  paymentLog: state.settings.paymentLog,
});

export default connect(
  mapStateToProps,
  { getPaymentLog }
)(Paymentlog)
