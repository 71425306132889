import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import square from '../../assets/images/Icons-nav-widgets.svg'
import widgetNew from '../../assets/images/Widget.svg'
import helpNew from '../../assets/images/help.svg'
import profileNew from '../../assets/images/Account.svg'
import squareActive from '../../assets/images/Icons-nav-widgets.svg'
import AvailableIcon from '../../assets/images/Available-icon.png'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { menus, Operatormenus } from './Menus'
import HoursModal from '../../common/HoursModal'
import ProfileModal from '../../common/ProfileModal'
import ReferModal from '../../common/ReferModal'
import { Button, Icon, Progress } from 'semantic-ui-react'
import NotificationModal from '../../common/NotificationModal'
import settings from '../../assets/images/Icons-nav-settings.svg'
import plusIcon from '../../assets/images/plus-icon-white.svg'
import { connect } from 'react-redux'
import { getDialer } from '../../actions/dialer'
import { truncate } from 'lodash'
import Axios from 'axios'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Slider,
  Tooltip
} from '@mui/material'
import Clarity from '../../containers/Clarity'
import CommonSubscriptionModal from '../../common/CommonSubscriptionModal'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'

const activeList = {
  activeNumbers: false,
  activeForms: false,
  activeSettings: false,
  activeCirle: false,
  activePeople: false,
  activeSquare: false,
  activeContact: false,
  activePhone: false,
  activeMyPhone: false,
  activeHome: false,
  activePhoneCall: false,
  activeIntegration: false,
  activeAnalytics: false,
  activeCampaign: false,
  activeConversation: false,
  activeTasks: false,
  activeMeets: false,
  activeBilling: false,
  open: false,
  activeMeeting: false,
  activeStart: false,
  activeAvailable: false,
  activeCallTracking: false,
  activePipeline: false,
  activeAddNumbers: false,
  activeKYC: false,
  activeLists: false
}

class Navbar extends Component {
  state = {
    ...activeList,
    usageData: {},
    activeComponent: 'MySettings',
    activeTab: 'account',
    leadCount: localStorage.getItem('pendingLeads'),
    showSubscriptionModal: false,
    urlLink: window?.location?.pathname
  }

  // componentDidUpdate() {
  //   let urlLink = window?.location?.pathname

  //   // urlLink = urlLink.split('/')
  //   // urlLink = '/' + urlLink[1]

  //   console.info({ urlLink })
  //   this.saveLeadDetail()
  //   switch (urlLink) {
  //     case '/':
  //       this.setState({ ...activeList, activeHome: true })
  //       break
  //     case '/conversation':
  //       this.setState({ ...activeList, activeConversation: true })
  //       break
  //     case '/callLog':
  //       this.setState({ ...activeList, activePhoneCall: true })
  //       break
  //     case '/home':
  //       this.setState({ ...activeList, activeHome: true })
  //       break
  //     case '/leads':
  //       this.setState({ ...activeList, activePhone: true })
  //       break
  //     case '/my-leads':
  //       this.setState({ ...activeList, activeMyPhone: true })
  //       break
  //     case '/contacts':
  //       this.setState({ ...activeList, activeContact: true })
  //       break
  //     case '/meetings':
  //       this.setState({ ...activeList, activeMeeting: true })
  //       break
  //     case '/meets':
  //       this.setState({ ...activeList, activeMeets: true })
  //       break
  //     case '/billing':
  //       this.setState({ ...activeList, activeBilling: true })
  //       break
  //     case '/widgets':
  //       this.setState({ ...activeList, activeSquare: true })
  //       break
  //     case '/integration':
  //       this.setState({ ...activeList, activeIntegration: true })
  //       break
  //     case '/analytics':
  //       this.setState({ ...activeList, activeAnalytics: true })
  //       break

  //     case '/campaign':
  //       this.setState({ ...activeList, activeCampaign: true })
  //       break
  //     case '/call-tracking':
  //       this.setState({ ...activeList, activeCallTracking: true })
  //       break

  //     case '/settings':
  //       this.setState({ ...activeList, activeSettings: true })
  //       break
  //     case '/numbers':
  //       this.setState({ ...activeList, activeNumbers: true })
  //       break
  //     case '/forms':
  //       this.setState({ ...activeList, activeForms: true })
  //       break
  //     case '/tasks':
  //       this.setState({ ...activeList, activeTasks: true })
  //       break
  //     case '/pipeline':
  //       this.setState({ ...activeList, activePipeline: true })
  //       break
  //     case '/settings/personal':
  //       this.setState({ ...activeList, activeSettings: true })
  //       break
  //     case '/settings/kyc':
  //       this.setState({ ...activeList, activeKYC: true })
  //       break
  //     case '/addNumbers':
  //       this.setState({ ...activeList, activeAddNumbers: true })
  //       break

  //     case '/settings/personal/working_hours':
  //       this.setState({ ...activeList, activeAvailable: true })
  //       break
  //     case '/lists':
  //       this.setState({ ...activeList, activeLists: true })
  //       break
  //     default:
  //       this.setState({ ...activeList })
  //       break
  //   }
  // }
  componentDidMount() {
    let urlLink = window?.location?.pathname
    // urlLink = urlLink.split('/')
    // urlLink = '/' + urlLink[1]

    console.info({ urlLink })
    this.saveLeadDetail()
    switch (urlLink) {
      case '/':
        this.setState({ ...activeList, activeHome: true })
        break
      case '/conversation':
        this.setState({ ...activeList, activeConversation: true })
        break
      case '/callLog':
        this.setState({ ...activeList, activePhoneCall: true })
        break
      case '/home':
        this.setState({ ...activeList, activeHome: true })
        break
      case '/leads':
        this.setState({ ...activeList, activePhone: true })
        break
      case '/my-leads':
        this.setState({ ...activeList, activeMyPhone: true })
        break
      case '/contacts':
        this.setState({ ...activeList, activeContact: true })
        break
      case '/meetings':
        this.setState({ ...activeList, activeMeeting: true })
        break
      case '/meets':
        this.setState({ ...activeList, activeMeets: true })
        break
      case '/billing':
        this.setState({ ...activeList, activeBilling: true })
        break
      case '/widgets':
        this.setState({ ...activeList, activeSquare: true })
        break
      case '/integration':
        this.setState({ ...activeList, activeIntegration: true })
        break
      case '/analytics':
        this.setState({ ...activeList, activeAnalytics: true })
        break

      case '/campaign':
        this.setState({ ...activeList, activeCampaign: true })
        break
      case '/call-tracking':
        this.setState({ ...activeList, activeCallTracking: true })
        break

      case '/settings':
        this.setState({ ...activeList, activeSettings: true })
        break
      case '/numbers':
        this.setState({ ...activeList, activeNumbers: true })
        break
      case '/forms':
        this.setState({ ...activeList, activeForms: true })
        break
      case '/tasks':
        this.setState({ ...activeList, activeTasks: true })
        break
      case '/pipeline':
        this.setState({ ...activeList, activePipeline: true })
        break
      case '/settings/personal':
        this.setState({ ...activeList, activeSettings: true })
        break
      case '/settings/kyc/phone_regulatory_bundles':
        this.setState({ ...activeList, activeKYC: true })
        break
      case '/addNumbers':
        this.setState({ ...activeList, activeAddNumbers: true })
        break
      case '/lists':
        this.setState({ ...activeList, activeLists: true })
        break

      case '/settings/personal/working_hours':
        this.setState({ ...activeList, activeAvailable: true })
        break
      default:
        this.setState({ ...activeList })
        break
    }
  }
  saveLeadDetail = () => {
    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/subscription/usage-stats`

    Axios.get(URL).then(res => {
      this.setState(state => ({ ...state, usageData: res.data.data }))
    })
  }
  changeActiveMenu(key) {
    let activeListCopy = { ...activeList }
    if (key !== 'activeSettings') {
      activeListCopy.activeSettings = false
      activeListCopy[key] = true
       if (key === 'activePeople') {
         this.setState(
           {
             ...activeListCopy,
             activeTab: 'Team',
             activeComponent: 'Users & Team'
           }
           // () => {
           //   return this.props.handleActive && this.props.handleActive(
           //     this.state.activeTab,
           //     this.state.activeComponent
           //   )
           // }
         )
       } else {

         this.setState(
           {
             ...activeListCopy,
             activeTab: 'Account',
             activeComponent: 'MySettings'
           }
           // () => {
           //   return this.props.handleActive && this.props.handleActive(
           //     this.state.activeTab,
           //     this.state.activeComponent
           //   )
           // }
         )
       }
    } else {
      activeListCopy.activeSettings = true
      activeListCopy[key] = true
       if (key === 'activePeople') {
         this.setState(
           {
             ...activeListCopy,
             activeTab: 'Team',
             activeComponent: 'Users & Team'
           }
           // () => {
           //   return this.props.handleActive && this.props.handleActive(
           //     this.state.activeTab,
           //     this.state.activeComponent
           //   )
           // }
         )
       } else {

         this.setState(
           {
             ...activeListCopy,
             activeTab: 'Account',
             activeComponent: 'MySettings'
           }
           // () => {
           //   return this.props.handleActive && this.props.handleActive(
           //     this.state.activeTab,
           //     this.state.activeComponent
           //   )
           // }
         )
       }
    }
  }
  openModal = () => {
    this.setState({ open: !this.state.open })
  }

  closeModal = () => {
    this.setState({ open: !this.state.open })
  }

  closeModal = () => {
    this.setState({ open: !this.state.open })
  }
  onCloseModal = () => {
    if (document.querySelector('.dimmer')) {
      document.querySelector('.dimmer').click()
    }
  }

  onRouteChanged() {
    console.info('ROUTE CHANGED')
  }

  activeHandle = component => {
    console.log('component', component)
    if (component === 'menuSettings') {
      this.changeActiveMenu('activeSettings')
    } else if (component === 'menuPeople') {
      this.changeActiveMenu('activePeople')
    } else if (component === 'menuAnalytics') {
      this.changeActiveMenu('activeAnalytics')
    } else if (component === 'menuCampaign') {
      this.changeActiveMenu('activeCampaign')
    } else if (component === 'menuCallTracking') {
      this.changeActiveMenu('activeCallTracking')
    } else if (component === 'menuNumbers') {
      this.changeActiveMenu('activeNumbers')
    } else if (component === 'menuAddNumbers') {
             this.changeActiveMenu('activeAddNumbers')
           } else if (component === 'menuKYC') {
             this.changeActiveMenu('activeKYC')
           } else if (component === 'menuForms') {
             this.changeActiveMenu('activeForms')
           } else if (component === 'menuSquare') {
             this.changeActiveMenu('activeSquare')
           } else if (component === 'menuAvailable') {
             this.changeActiveMenu('activeAvailable')
           } else if (component === 'menuContact') {
             this.changeActiveMenu('activeContact')
           } else if (component === 'menuLists') {
                    this.changeActiveMenu('activeLists')
                  } else if (component === 'menuPhone') {
                    this.changeActiveMenu('activePhone')
                  } else if (component === 'menuMyPhone') {
                    this.changeActiveMenu('activeMyPhone')
                  } else if (component === 'menuHome') {
                    this.changeActiveMenu('activeHome')
                  } else if (component === 'menuPhoneCall') {
                    this.changeActiveMenu('activePhoneCall')
                  } else if (component === 'menuConversation') {
                    this.changeActiveMenu('activeConversation')
                  } else if (component === 'menuTasks') {
                    this.changeActiveMenu('activeTasks')
                  } else if (component === 'menuMeets') {
                    this.changeActiveMenu('activeMeets')
                  } else if (component === 'menuContact') {
                    this.changeActiveMenu('activeContact')
                  } else if (component === 'menuMeeting') {
                    this.changeActiveMenu('activeMeeting')
                  } else if (component === 'menuBilling') {
                    this.changeActiveMenu('activeBilling')
                  } else if (component === 'menuPipeline') {
                    this.changeActiveMenu('activePipeline')
                  } else if (component === 'menuStart') {
                    this.changeActiveMenu('activeStart')
                  } else {
                    this.changeActiveMenu('activeCirle')
                  }
  }

  goToTeam = () => {
    window.location.href = '/settings/users_&_teams/users'
  }

  openDialer = () => {
    this.props.getDialer(!this.props.dialerStatus)
  }


  render() {
    console.log('this.state', this.props)

    const role_name = localStorage.getItem('role_name')
    const unreadMessage = localStorage.getItem('unread_message')
    const accountStatus = localStorage?.getItem('account_status')
    const planName = localStorage?.getItem('plan_name')
    return (
      <div className="nav-container custome_nav_bar billing">
        <div className="top-nav">
          {(role_name === 'Admin' ||
          role_name === 'Operation Manager' ||
          role_name === 'Widget Manager' ||
          role_name === 'Manager'
            ? menus
            : Operatormenus
          ).map((menu_item, index) =>
            menu_item?.subMenu?.length ? (
              <Accordion
                onClick={() => {
                  this.activeHandle(menu_item.handle)
                }}
                key={index}
                slotProps={{ heading: { component: 'h4' } }}
                sx={{
                  background: 'black',
                  color: 'white',
                  '&.MuiAccordion-root': {
                    margin: '2px 0',
                    '&.Mui-expanded': {
                      margin: '2px 0'
                    }
                  },
                  '& .MuiAccordionSummary-root': {
                    minHeight: 'auto',
                    paddingLeft: '18px',
                    '&.Mui-expanded': {
                      minHeight: 'auto'
                    }
                  },
                  '& .MuiAccordionSummary-content': {
                    margin: '2px 0',
                    padding: '5px',
                    '&.Mui-expanded': {
                      margin: '2px 0'
                    }
                  }
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  style={{ paddingTop: '5px !important' }}
                  className={classNames('menu-link', {
                    'nav-active': this.state[menu_item.stateKey]
                  })}
                  onClick={e => {
                    e.stopPropagation()
                    this.activeHandle(menu_item.handle)
                    this.props.history.push(menu_item?.url)
                  }}
                >
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      style={{ color: '#fff', marginRight: '5px' }}
                      src={menu_item.inactiveIcon}
                      alt="icon"
                    />
                    <span style={{ fontSize: '12px' }}>
                      {menu_item?.hoverName}
                    </span>
                  </span>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0, paddingLeft: '10px' }}>
                  {menu_item?.subMenu.map((sub_menu_item, ind) => (
                    <Link
                      key={ind}
                      className={classNames('menu-link sub-menu', {
                        'nav-active': this.state[sub_menu_item.stateKey]
                      })}
                      onClick={e => {
                        e.stopPropagation()
                        this.activeHandle(sub_menu_item.handle)
                      }}
                      to={sub_menu_item.url}
                      style={{ padding: '0px !important' }}
                    >
                      <div className="icon-wrapper">
                        {sub_menu_item?.showCount &&
                          (sub_menu_item?.hoverName === 'Leads'
                            ? this.props.pendingLeads > 0 ||
                              localStorage?.getItem('pendingLeads') > 0
                            : sub_menu_item?.count > 0) && (
                            <span className="count">
                              {sub_menu_item?.hoverName === 'Leads'
                                ? this.props.pendingLeads ||
                                  localStorage?.getItem('pendingLeads')
                                : sub_menu_item?.count}
                            </span>
                          )}
                        {sub_menu_item.inactiveIcon ? (
                          <img
                            style={{ color: '#fff' }}
                            src={sub_menu_item.inactiveIcon}
                            alt="icon"
                          />
                        ) : null}
                        <span>{sub_menu_item.hoverName}</span>
                      </div>
                    </Link>
                  ))}
                </AccordionDetails>
              </Accordion>
            ) : (
              <Link
                key={index}
                className={classNames('menu-link', {
                  'nav-active': this.state[menu_item.stateKey]
                })}
                onClick={() => this.activeHandle(menu_item.handle)}
                to={menu_item.url}
              >
                <div className="icon-wrapper">
                  {menu_item?.showCount &&
                    (menu_item?.hoverName === 'Leads'
                      ? this.props.pendingLeads > 0 ||
                        localStorage?.getItem('pendingLeads') > 0
                      : menu_item?.count > 0) && (
                      <span className="count">
                        {menu_item?.hoverName === 'Leads'
                          ? this.props.pendingLeads ||
                            localStorage?.getItem('pendingLeads')
                          : menu_item?.count}
                      </span>
                    )}
                  <img
                    style={{ color: '#fff' }}
                    src={menu_item.inactiveIcon}
                    alt="icon"
                  />
                  <span>{menu_item.hoverName}</span>
                </div>
                {/* <div className="hover-name-wrapper">
                    <i className="fas fa-caret-left"></i>
                    <p>{menu_item.hoverName}</p>
                  </div> */}
              </Link>
            )
          )}
        </div>

        <div className="bottom-nav">
          {/* <div className="menu-link">
            <div className="icon-wrapper" onClick={this.openDialer}>
              <Icon
                name="fas fa-tty"
                color="white"
                style={{ color: 'white' }}
              ></Icon>
            </div>
            <div className="hover-name-wrapper">
              <i className="fas fa-caret-left"></i>
              <p>Dialer</p>
            </div>
          </div> */}
          <Clarity />

          <div
            className={classNames('menu-link', {
              'nav-active': this.state['activeSquare']
            })}
            onClick={() => (window.location.href = '/widgets')}
          >
            <div className="icon-wrapper">
              <img
                src={this.state.activeSquare ? squareActive : square}
                alt="icon"
              />
              <span>Widget</span>
            </div>
            {/* <div className="hover-name-wrapper">
              <i className="fas fa-caret-left"></i>
              <p>Widget</p>
            </div> */}
          </div>

          {/* <div
            className={classNames('menu-link', {
              'nav-active': this.state['activeAvailable']
            })}
            onClick={() =>
              (window.location.href = '/settings/personal/working_hours')
            }
          > */}
            {/* <div className="icon-wrapper">
              <img
                src={this.state.activeAvailable ? AvailableIcon : AvailableIcon}
                alt="icon"
                style={{ height: '20px' }}
              />
              <span>Availability</span>
            </div> */}
            {/* <div className="hover-name-wrapper">
              <i className="fas fa-caret-left"></i>
              <p>Widget</p>
            </div> */}
          {/* </div> */}
          {/* <div
            className={classNames('menu-link')}
            onClick={() => (window.location.href = '/settings/teams')}
          >
            <div className="icon-wrapper">
              <img src={plusIcon} alt="icon" />
              <span>Invite team</span>
            </div>
         
          </div> */}

          <HoursModal />

          <Tooltip
            placement="right"
            title={
              accountStatus === 'in_trial'
                ? '3 days trial left'
                : accountStatus === 'trial_expired'
                ? 'pick a plan'
                : 'Lead usage count'
            }
          >
            <div className="main-box" style={{ marginTop: 10 }}>
             
              <div className="profile-content" style={{ textAlign: 'center' }}>
                <p>{planName} Plan</p>
              </div>
              <p style={{margin:'auto'}}>

Credit : $
{this.props?.subscription?.credits_in_dollars
? this.props.subscription?.credits_in_dollars.toFixed(2)
: 0}
  </p>
              <div className="count-list-main">
                <div className="count-list">
                  {(this.state.usageData?.max_callbacks ||
                    this.state.usageData?.max_callbacks === 0) && (
                    <p
                      style={{
                        color:
                          this.state.usageData?.used_callbacks >=
                          this.state.usageData?.max_callbacks
                            ? 'red'
                            : undefined
                      }}
                    >
                     Callbacks :  <b> {this.state.usageData?.used_callbacks}</b> /
                      {this.state.usageData?.max_callbacks} 
                    </p>
                  )}
                </div>
                <Progress
                value={this.state.usageData?.used_callbacks}
                error={
                  this.state.usageData?.used_callbacks >=
                  this.state.usageData?.max_callbacks
                }
                total={this.state.usageData?.max_callbacks}
                className="sidebar-progress"
              />
                <div className="count-list" style={{ marginTop: 4 }}>
                  {(this.state.usageData?.max_leads ||
                    this.state.usageData?.max_leads === 0) && (
                    <p
                      style={{
                        color:
                          this.state.usageData?.used_leads >=
                          this.state.usageData?.max_leads
                            ? 'red'
                            : undefined
                      }}
                    >
                    Leads:   <b>{this.state.usageData?.used_leads}</b> /
                      {this.state.usageData?.max_leads} 
                    </p>
                  )}
                </div>
              </div>
              <Progress
                value={this.state.usageData?.used_leads}
                error={
                  this.state.usageData?.used_leads >=
                  this.state.usageData?.max_leads
                }
                total={this.state.usageData?.max_leads}
                className="sidebar-progress"
              />
             {this.props.subscription?.plan_status!=="paid_active" ? <Button
                content="upgrade"
                className="sidebar-btn"
                onClick={() => this.setState({ showSubscriptionModal: true })}
              />: null}
            </div>
          </Tooltip>
          <div
            className={classNames('menu-link', {
              'nav-active': this.state['activeSquare']
            })}
          >
            {/* <div className="hover-name-wrapper">
              <i className="fas fa-caret-left"></i>
              <p>Widget</p>
            </div> */}
          </div>

          {/* <div className="hover-name-wrapper">
              <i className="fas fa-caret-left"></i>
              <p>How can we help?</p>
            </div> */}

          {/* <Link

                  className={classNames('menu-link', {
                    'nav-active': this.state['activeSettings']
                  })}
                  onClick={() => {
                    this.activeHandle('menuSettings')
                  }}
                  to={'/settings/personal'}
                >
                  <div className="icon-wrapper">
                    
                    <img src={settings} alt="icon" />
                  <span>Settings</span>
                  </div>
                  {/* <div className="hover-name-wrapper">
                    <i className="fas fa-caret-left"></i>
                    <p>{menu_item.hoverName}</p>
                  </div> 
                </Link> */}
          <CommonSubscriptionModal
            open={this.state.showSubscriptionModal}
            onClose={() => this.setState({ showSubscriptionModal: false })}
            title="Choose Your Plan"
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    dialerStatus: state.dialer.currentStatus,
    pendingLeads: state.settings.pendingLeads,
    subscription: state.home.subscription?.data
  }
}

export default connect(mapStateToProps, { getDialer })(withRouter(Navbar))
