import React from 'react'
import mobileImg from '../../assets/images/mobile.jpg'
import { Button } from '@mui/material'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
const MobileScreen = () => {
    const history = useHistory()
  return (
    <div
      className="text-center d-flex"
      style={{
        padding: 15,
        margin: 'auto',
        flexDirection: 'column',
        justifyContenr: 'center'
      }}
    >
      <img src={mobileImg} alt="d" />
      <h2>Switch to desktop for admin access </h2>
      <p>
        For full admin access and advanced features,. please login from your
        desktop computer.
      </p>
      <p>For quick calls and texts, access the mobile dashboard</p>
      <Button
        variant="contained"
        style={{
          backgroundColor: 'blue',
          marginBottom: '15px',
          width: 'fit-content',
          margin: 'auto'
        }}
        onClick={() => window.open('https://dialer.limecall.com/')}
      >
        Continue to Mobile Dashboard
      </Button>
      <Button
        variant="contained"
        style={{
          backgroundColor: 'blue',
          width: 'fit-content',
          margin: '15px auto'
        }}
        onClick={() =>
          window.open(
            'https://play.google.com/store/apps/details?id=com.limecall'
          )
        }
      >
        Download android app
      </Button>
    </div>
  )
}

export default MobileScreen