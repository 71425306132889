import React, { Component, useEffect, useState } from 'react'

import Title from '../common/Title'
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import InnerNavigation from '../common/InnerNavigation'
import icon from '../assets/images/settingIcon.png'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Button, Popover, Typography } from '@mui/material'

const titleContent = {
  type: 'image',
  titleOne: icon,
  titleTwo: 'Phone Numbers'
}

const settingsData = [
  {
    mainSidebar: 'Manage Number',
    innerTabs: ['Manage Number']
  }
]

export const Numbers=(props)=>{
  const [sideTab, setSideTab] = useState(0)
  const navigate=useHistory()
  useEffect(() => {
document.title = 'Numbers'
  }, [])
  
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)


    return (
      <div className="fullwidth-container number-container" style={{margin:'auto'}}>
        {/* <div className="sidebarWrap">
          <div
            className="meetingSidebar"
            style={{
              left: '120px',
              top: '62px',
              paddingTop: '20px',
              textAlign: 'center'
            }}
          >
            <label
              className={sideTab == 0 ? 'sideTab' : ''}
              onClick={() => {
                setSideTab(0)
              }}
            >
              Manage numbers
            </label>

            <label
              // className={sideTab == 2 ? 'sideTab' : ''}
              onClick={() => {
                navigate.push('/settings/kyc')
              }}
            >
              Addresses
            </label>
          </div>
          <div className="numbers-banner-container manage_number_main">
            {sideTab === 0 ? (
              <>
                <div
                  className="numbers-banner justify-content-end"
                  style={{ padding: '30px' }}
                >
                  {/* <div className="d-flex">
                    <div className="banner-icon">
                      <img src={icon} alt="icon" />
                    </div>
                    <div className="banner-description-wrapper">
                      <p
                        className="banner-description"
                        style={{ fontSize: '22px' }}
                      >
                        Manage your Virtual phone numbers.
                      </p>
                    </div>
                  </div> */}

                  {/* <HelpOutlineOutlinedIcon
                    aria-owns={open ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    fontSize="large"
                  />
                  <Popover
                    id="mouse-over-popover"
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left'
                    }}
                    // disableRestoreFocus
                  >
                    <div
                      style={{ padding: '15px' }}
                      onMouseLeave={handlePopoverClose}
                    >
                      <div className="d-flex justify-content-between">
                        <h2>Need help?</h2>
                        <CloseIcon onClick={() => handlePopoverClose()} />
                      </div>
                      <p>
                        Watch a tour of managing virtual numbers or check out{' '}
                        <br />
                        our virtual number knowledgebase
                      </p>
                      <Button
                        onClick={() =>
                          window.open('https://youtu.be/OLb_QI6bhvE')
                        }
                        variant="contained"
                      >
                        Watch demo
                      </Button>
                      <Button
                        onClick={() =>
                          window.open(
                            'https://docs.limecall.com/agents-usage-guide/virtual-numbers/manage-your-virtual-number'
                          )
                        }
                        className="ml-3"
                        variant="outlined"
                      >
                        Knowledge base
                      </Button>
                    </div>
                  </Popover> 
                </div>

                <InnerNavigation
                  dataComponent={settingsData}
                  activeTab={props.activeTab}
                  activeComponent={props.activeComponent}
                  dataTitle={titleContent}
                />
              </>
            ) : (
              ''
            )}
          </div>
        </div> */}
        <>
          <div
            className="numbers-banner justify-content-end"
            style={{ padding: '30px' }}
          >
           

            {/* <HelpOutlineOutlinedIcon
                    aria-owns={open ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    fontSize="large"
                  />
                  <Popover
                    id="mouse-over-popover"
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left'
                    }}
                    // disableRestoreFocus
                  >
                    <div
                      style={{ padding: '15px' }}
                      onMouseLeave={handlePopoverClose}
                    >
                      <div className="d-flex justify-content-between">
                        <h2>Need help?</h2>
                        <CloseIcon onClick={() => handlePopoverClose()} />
                      </div>
                      <p>
                        Watch a tour of managing virtual numbers or check out{' '}
                        <br />
                        our virtual number knowledgebase
                      </p>
                      <Button
                        onClick={() =>
                          window.open('https://youtu.be/OLb_QI6bhvE')
                        }
                        variant="contained"
                      >
                        Watch demo
                      </Button>
                      <Button
                        onClick={() =>
                          window.open(
                            'https://docs.limecall.com/agents-usage-guide/virtual-numbers/manage-your-virtual-number'
                          )
                        }
                        className="ml-3"
                        variant="outlined"
                      >
                        Knowledge base
                      </Button>
                    </div>
                  </Popover> */}
          </div>

          <InnerNavigation
            dataComponent={settingsData}
            activeTab={props.activeTab}
            activeComponent={props.activeComponent}
            dataTitle={titleContent}
          />
        </>
      </div>
    )
  
}

export default Numbers
