import React, { useState, useEffect, useRef } from 'react'
import PhoneInput from 'react-phone-input-2'
import { connect } from 'react-redux'
import { getWidgetCountries } from '../../../actions/settings'
import { getNumbers } from '../../../actions/numbers'

import {
  Button,
  Dropdown,
  Header,
  Modal,
  Input,
  TextArea
} from 'semantic-ui-react'

import CommonButtons from '../../../common/CommonButtons'

import CountryData from '../../../helpers/data/countryCode.json'
import { useGetContactsQuery } from '../../../features/api/apiSlice'

const SendMessageModal = ({
  open,
  onClose,
  setupConversation,
  getWidgetCountries,
  widgetCountries,
  numbers,
  getNumbers
}) => {
  const [allowedCountries, setAllowedCountries] = useState([])
  const [dropDownData, setDropDownData] = useState([])
  const [contactOptions, setContactOptions] = useState([])
 const resContacts = useGetContactsQuery({ date: 'all', type: 'all' })
  const [sendMsgData, setSendMsgData] = useState({
    from: null,
    to: '',
    message: '',
  })
  const textPos = useRef(null)

  useEffect(() => {
    if (numbers) {
      let tempRows = []
      if (numbers.data) {
        let tempData = [...numbers.data]
        tempData.map((row, index) => {
          if (!row.primary) {
            let tempObject = {
              id: row.id,
              value: row.phone_number,
              text: `${row.friendly_name} - ${row.phone_number}`
            }
            tempRows.push(tempObject)
          }
        })
      }
      setSendMsgData({ ...sendMsgData, from: +tempRows[0]?.value })
      setDropDownData(tempRows)
    } else {
      getNumbers()
    }
  }, [numbers])
  
    const handleContactSearch = query => {
      if (query) {
        const filteredContacts = resContacts?.data?.data
          ?.filter(
            contact =>
              contact?.phone_number?.includes(query) ||
              contact?.name?.includes(query)
          )
          .map(contact => ({
            key: contact.id,
            text: contact.phone_number,
            value: contact.phone_number
          }))
        setContactOptions(filteredContacts)
      } else {
        setContactOptions([])
      }
      setSendMsgData({ ...sendMsgData, to: query })
    }


  useEffect(() => {
    resetWidgetCountries(widgetCountries?.data?.allowed_countries)
  }, [JSON.stringify(widgetCountries)])

  useEffect(() => {
    if (!open) return
    if (allowedCountries?.length) return

    getWidgetCountries()
  }, [open])

  const resetWidgetCountries = countries => {
    const allCountry = CountryData
    setAllowedCountries(allCountry
    ?.filter(item => {
      if (countries?.includes(item.name)) {
        return item
      }
    })
    .map(item => item.code.toLowerCase()))
  }

  return (
    <>
      <Modal
        className="send-message-modal"
        onClose={onClose}
        closeIcon
        open={open}
        size="tiny"
      >
        <Header content="Create New Conversation" />
        <Modal.Content style={{ padding: '30px' }}>
          <div className="send-message__dropdown">
            <label>From *</label>
            <Dropdown
              name="from"
              placeholder="Select Number"
              selection
              value={`+${sendMsgData.from}`}
              options={dropDownData}
              onChange={(e, data) =>
                setSendMsgData({ ...sendMsgData, from: +data.value })
              }
            />
          </div>
          <div className="send-message__input">
            <label>To </label>
            <Dropdown
              fluid
              search
              selection
              placeholder="Type a number"
              options={contactOptions}
              onSearchChange={(e, data) =>
                handleContactSearch(data.searchQuery)
              }
              onChange={(e, data) =>
                setSendMsgData({ ...sendMsgData, to: data.value })
              }
              value={sendMsgData.to}
            />
            {/* <Input
            placeholder="Enter a number or phone number"
            value={sendMsgData.to}
            onChange={e =>
              setSendMsgData({ ...sendMsgData, to: e.target.value })
            }
          /> */}
          </div>
          <div className="send-message__textarea">
            <label>Message * </label>
            <TextArea
              maxlength="160"
              name="message"
              value={sendMsgData.message}
              onChange={e =>
                setSendMsgData({ ...sendMsgData, message: e.target.value })
              }
              rows={2}
              title="Message *"
            />
            <br />
            <p>Character left {160 - sendMsgData.message?.length} </p>
          </div>
        </Modal.Content>
        <Modal.Actions className="send-message__btn">
          <Button onClick={onClose} className="send-message__btn-grey">
            close
          </Button>
          <CommonButtons
            content="Send SMS"
            btnClass="send-message__btn-blue"
            background="#007bff"
            style={{ color: 'white' }}
            onClick={e => setupConversation(sendMsgData)}
          />
        </Modal.Actions>
      </Modal>
    </>
  )
}

const mapStateToProps = state => {
  return {
    widgetCountries: state.settings.widgetCountries,
    numbers: state.numbers.numbers
  }
}

export default connect(mapStateToProps, { getWidgetCountries, getNumbers })(
  SendMessageModal
)
