import React from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { Button } from 'semantic-ui-react'
import goLiveRocket from '../../assets/images/goliverocket.svg'
import CommonButtons from '../../common/CommonButtons'
import { CommonNotify } from '../../common/CommonNotify'
import { isMobile } from 'react-device-detect';

const GoLiveTeamMember = props => {
  const history = useHistory()
  const skipOnboarding = async() => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/skip-onboarding`
    axios
      .post(url, {})
      .then(res => {
        CommonNotify('Successfully updated', 'success')
        if (isMobile){
          window.location.href = 'https://dialer.limecall.com/'

        }else{
          window.location.assign(isMobile ? '/to-dialer' :"/home")
        }
         
       
      })
      .catch(err => {})
  }

  const goToTeam = () => {
    window.location.href = '/settings/team/manage_teammates'
  }
  return (
    <div className="golive">
      <div className="golive-content">
        <img src={goLiveRocket} alt="" />
        <p className="golive-title">You are good to go</p>
        <p className="golive-subtitle">It's time to grow with LimeCall</p>
        {/* <p className="golive-desc">
          I would like a one to one personalized demo
        </p> */}
        <div className="golive-btn-holder">
          <CommonButtons
            content="Go to Dashboard"
            background="blue"
            btnClass="btn-modal-style"
            onClick={() => skipOnboarding()}
          />
          <CommonButtons
            onClick={() => window.open('https://limecall.com/demo/')}
            content="Book a demo"
            background="alt-blue"
            btnClass="btn-modal-style"
          />
        </div>
        <div className='quicksetup-footer footer-button mt-auto w-100'>
          <CommonButtons
          content="Back"
          btnClass="btn-modal-style btn-back"
          onClick={props.prev}
        />
        </div>
      </div>
    </div>
  )
}

export default GoLiveTeamMember
