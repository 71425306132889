/* eslint-disable jsx-a11y/alt-text */
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import SearchIcon from '@mui/icons-material/Search'
import {
  Autocomplete,
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Typography
} from '@mui/material'
import axios from 'axios'
import * as FileSaver from 'file-saver'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css'
import {
  Button,
  Dimmer,
  Dropdown,
  Input,
  Loader,
  Modal,
  Popup,
  Tab
} from 'semantic-ui-react'
import * as XLSX from 'xlsx'
import { getCallerId, getTeamMembers } from '../actions/settings'
import adduser from '../assets/images/adduser.png'
import assignAgent from '../assets/images/assignAgent.png'
import calendarIcon from '../assets/images/calendar.png'
import cameFrom from '../assets/images/cameFrom.png'
import city from '../assets/images/city.png'
import closeGray from '../assets/images/closeblack.png'
import closeIcon from '../assets/images/closeIcon.png'
import countryIcon from '../assets/images/countryIcon.png'
import crosGray from '../assets/images/crosGray.png'
import dotIcon from '../assets/images/dot.svg'
import email from '../assets/images/email.png'
import group from '../assets/images/group.png'
import ipAddress from '../assets/images/ipAddress.png'
import lastPageTitle from '../assets/images/lastPageTitle.png'
import leadScoreIcon from '../assets/images/leadScore.png'
import leadStageIcon from '../assets/images/leadStage.png'
import nameIcon from '../assets/images/name.png'
import returnCustomer from '../assets/images/returnCustomer.png'
import visitNo from '../assets/images/visitNo.png'
import WebCrawlers from '../assets/images/webCrawlers.png'
import CommonButton from '../common/CommonButtons'
import CommonInput from '../common/CommonInput'
import { CommonNotify } from '../common/CommonNotify'
import Title from '../common/Title'
import MessageToContact from '../components/contacts/MessageToContact'
import EverGreenUploadCsv from '../components/contacts/uploadCsv'
import AddContactModal from '../components/contactsPanels/addNewContact'
import CompanyDetailsMain from '../components/contactsPanels/companyDetailsMain'
import ContactModal from '../components/contactsPanels/contactDetails'
import ContactsPanels from '../components/contactsPanels/contactTabs'
import VipFilterData from '../components/contactsPanels/VipData'
import CommonDateRangePicker from '../components/daterangepicker/CommonDateRangePicker'
import { useGetContactsQuery } from '../features/api/apiSlice'
import BulkOperationMenu from './bulkOperationMenu'
import {ReactComponent as PlusIcon} from '../assets/images/plus.svg'
import ListMain from '../components/contactsPanels/ListMain'
const title = {
  titleTwo: 'Contacts'
}

const type = ['Organization', 'Individual']
const status = ['Opening', 'Customer', 'Lead']
const score = ['Warm', 'Hold', 'Cold']

const options = [
  { value: 'all', text: 'All Time', key: 1 },
  { value: 'today', text: 'Today', key: 2 },
  { value: 'week', text: 'This Week', key: 3 },
  { value: 'month', text: 'This Month', key: 4 }
]

const countryOptions = [
  { key: 'af', value: 'af', text: 'Activity' },
  { key: 'ax', value: 'ax', text: 'Assigned agent' },
  { key: 'al', value: 'al', text: 'Group' },
  { key: 'dz', value: 'dz', text: 'Country' },
  { key: 'as', value: 'as', text: 'City' },
  { key: 'ad', value: 'ad', text: 'Name' },
  { key: 'ao', value: 'ao', text: 'Email' },
  { key: 'ai', value: 'ai', text: 'Returning customer' },
  { key: 'ag', value: 'ag', text: 'Numbers of visits' },
  { key: 'ar', value: 'ar', text: 'Web crawlers' },
  { key: 'am', value: 'am', text: 'IP address' },
  { key: 'aw', value: 'aw', text: 'Came from' },
  { key: 'au', value: 'au', text: 'Last page title' }
]
const filterOptions = [
  {
    key: '3',
    value: 'country',
    text: (
      <>
        <span>
          <img src={countryIcon} className="popupImg" />
          Country
        </span>
      </>
    )
  },
  {
    key: '4',
    value: 'city',
    text: (
      <>
        <span>
          <img src={city} className="popupImg" />
          City
        </span>
      </>
    )
  },
  {
    key: '5',
    value: 'name',
    text: (
      <>
        <span>
          <img src={nameIcon} className="popupImg" />
          Name
        </span>
      </>
    )
  },
  {
    key: '6',
    value: 'email',
    text: (
      <>
        <span>
          <img src={email} className="popupImg" />
          Email
        </span>
      </>
    )
  }
]

const Contacts = props => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [Loading, setLoading] = useState(true)
  const [addOpen, setAddOpen] = useState(false)
  const [listModal, setListModal] = useState(false)
  const [allData, setAllData] = useState([])
  const [contactData, setContactData] = useState([])
  const [companyData, setCompanyData] = useState([])
  const [segementData, setSegementData] = useState([])
  const [filter, setFilter] = useState(contactData)
  const [opendetails, setOpen] = React.useState(false)
  const [getData, setSingleData] = useState({})
  const [vipstate, setVipState] = useState(false)
  const [saveSegment, setSegment] = useState(false)
  const [filterModal, setModal] = useState(false)
  const [radioState, setRadio] = useState(false)
  const [searchText, setSearchText] = useState([])
  const [dataVip, setData] = useState([contactData])
  const [iconValue, setValueIcon] = useState('')
  const [segmentModal, setSegmentModal] = useState(false)
  const [segmentText, setTextSegment] = useState('')
  const [logData, setLogData] = useState('')
  const [companyLength, setLength] = useState()
  const [filterText, setFilterText] = useState('')
  const [Allmember, setMembers] = useState(null)
  const [messageModal, setMessageModal] = useState(false)
  const [numberDropdown, setNumberDropdown] = useState([])
  const [phone_number, setPhoneNumber] = useState('')
  const [contactID, setContactID] = useState(null)
  const [showUpload, setUploadModal] = useState(false)
  const [showTrashIcon, setShowTrashIcon] = useState({ id: '', isShow: false })
  const [selectedContactsTab, setSelectedContactsTab] = useState(null)
  const [selectedPickerOption, setSelectedPickerOption] = useState(false)
  const [startDateFilter, setStartDateFilter] = ''
  const [endDateFilter, setEndDateFilter] = ''
  const [activeType, setActiveType] = useState('contact')
  let [isMarkAllCheckbox, setisMarkAllCheckbox] = useState(false)
  let [isMarkAllCheckbox2, setisMarkAllCheckbox2] = useState(false)
  let [showExport, setShowExport] = useState(false)
  let [showExport2, setShowExport2] = useState(false)
  let [selectedSegment, setSelectedSegment] = useState(false)
  const [selectedDate, setSelectedDate] = useState({ date: 'all', type: 'all' })
  const [open, setOpenList] = useState(true)
  const [deleteModal, setDeleteModal] = useState(false)
  const [editId, setEditId] = useState('')
  const [editedName, setName] = useState('')
  const [openListModel, setOpenListModel] = useState(false)
const [saveList, setSaveList] = useState('')
const [listData, setListData] = useState([])
  
  
  const hideButton =
    isMarkAllCheckbox || isMarkAllCheckbox2 || showExport || showExport2

  const exportToExcel = (apiData, fileName) => {
    //return;
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const ws = XLSX.utils.json_to_sheet(apiData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
  }
  const exportToExcelNew = (apiData, fileName) => {
    let ids = apiData.join(',')

    const url = `${process.env.REACT_APP_BASE_APP_URL}/export-contacts?exportType=csv&contactIds=${ids}`

    axios
      .get(url)
      .then(res => {
      })
      .catch(err => {
        setLoading(false)
      })
  }

  const onClickMarkCheckbox = (e, index) => {
    const checkBoxes = [...contactData]
    checkBoxes[index] = {
      ...checkBoxes[index],
      isChecked: !checkBoxes[index].isChecked
    }
    setContactData(checkBoxes)
    setShowExport(checkBoxes[index].isChecked)
  }

  const onClickMarkCheckbox2 = (e, index) => {
    const checkBoxes = [...companyData]
    checkBoxes[index] = {
      ...checkBoxes[index],
      isChecked: !checkBoxes[index].isChecked
    }
    setCompanyData(checkBoxes)
    setShowExport2(checkBoxes[index].isChecked)
  }

  const checkhckBoxes = () => {
    let state = false
    const checkBoxes = contactData
    const list = checkBoxes.map(data => {
      if (data.isChecked) {
        state = true
      }
    })
    setShowExport(state)
  }

  const checkhckBoxes2 = () => {
    let state = false
    const checkBoxes = companyData
    const list = checkBoxes.map(data => {
      if (data.isChecked) {
        state = true
      }
    })
    setShowExport2(state)
  }

  const onClickMarkAllCheckbox = () => {
    let tableDataContent = []
    contactData.map(data => {
      tableDataContent.push({
        ...data,
        isChecked: !isMarkAllCheckbox
      })
    })
    setContactData(tableDataContent)
    setShowExport(!isMarkAllCheckbox)
    setisMarkAllCheckbox(!isMarkAllCheckbox)
  }

  const onClickMarkAllCheckbox2 = () => {
    let tableDataContent = []
    companyData.map(data => {
      tableDataContent.push({
        ...data,
        isChecked: !isMarkAllCheckbox2
      })
    })
    setCompanyData(tableDataContent)
    setLength(tableDataContent?.length)
    setShowExport2(!isMarkAllCheckbox2)
    setisMarkAllCheckbox2(!isMarkAllCheckbox2)
  }

  const onClickUnmarkCheckbox = () => {
    let tableDataContent = []

    if (!contactData?.length) return

    setisMarkAllCheckbox(false)
    tableDataContent = contactData.map(data => {
      return {
        ...data,
        isChecked: false
      }
    })
    setContactData(tableDataContent)
    setShowExport(false)
  }

  const onClickUnmarkCheckbox2 = () => {
    let tableDataContent = []

    if (!companyData?.length) return

    setisMarkAllCheckbox2(false)
    tableDataContent = companyData.map(data => {
      return {
        ...data,
        isChecked: false
      }
    })
    setCompanyData(tableDataContent)
    setShowExport2(false)
  }

  const getSelectedContact = () => {
    const sel = []
    const run = contactData.map(data => {
      if (data.isChecked) {
        let dt = { ...data }
        delete dt['isChecked']
        sel.push(dt)
      }
    })
    return sel
  }

  const getSelectedContact2 = () => {
    const sel = []
    const run = companyData.map(data => {
      if (data.isChecked) {
        let dt = { ...data }
        delete dt['isChecked']
        sel.push(dt)
      }
    })
    return sel
  }

  const deleteSelected = async () => {
    const ids = getSelectedContact().map(el => el.id)
    setDeleteModal(false)
    setLoading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/delete-contacts`
    axios
      .post(url, { ids })
      .then(res => {
        if (res.data) {
          onClickUnmarkCheckbox()
          CommonNotify('Successfully deleted selected contacts!', 'success')
          fetchAllContacts()
        }
      })
      .catch(err => {
        setLoading(false)
      })
  }

  const deleteSelected2 = async () => {
    const ids = getSelectedContact2().map(el => el.id)
    setDeleteModal(false)
    setLoading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/delete-contacts`
    axios
      .post(url, { ids })
      .then(res => {
        if (res.data) {
          setLoading(false)
          onClickUnmarkCheckbox2()
          CommonNotify('Successfully deleted selected contacts!', 'success')
          fetchAllContacts()
        }
      })
      .catch(err => {
        setLoading(false)
      })
  }

  const createList = async (type) => {

    setDeleteModal(false)
    setLoading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/list`
    axios
      .post(url, { name: saveList, contactIds: [], contact_type: type})
      .then(res => {
        if (res.data) {
        setTextSegment(null)
          fetchSegments()
          fetchLists()
setOpenListModel(false)
          CommonNotify('Successfully Added!', 'success')
          fetchAllContacts()
        }
      })
      .catch(err => {
        setLoading(false)
      })
  }

  const handleChanges = evt => {
    setFilterText(evt)
  }

  const setOpens = () => {
    setOpen(true)
  }

  const handlefilterText = e => {
    const { name, value } = e.target
    setFilterText(value)
  }
  const handleSegmentChange = evt => {
    setTextSegment(evt.target.value)
  }
  const segmentHandleClick = e => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/list`

    const contactIds = [...companyData, ...contactData]
      ?.filter(el => el.isChecked)
      .map(el => el.id)

    const listID = segementData?.filter(el => el.name === segmentText)?.[0]
    if (listID) {
      setLoading(true)
      axios
        .post(
          `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/list/${listID.id}`,
          {
            name: segmentText,
            contactIds: [...listID?.contacts_ids,...contactIds],
            contact_type: activeIndex ? 'organization' : 'individual'
          }
        )
        .then(res => {
          CommonNotify('Added Successfully', 'success')
          
          fetchSegments()
          setContactData([...contactData?.map((obj)=>({...obj,['isChecked']:false}))])
          setCompanyData([...companyData?.map((obj)=>({...obj,['isChecked']:false}))])

          setLoading(false)
        })
        .catch(err => {
          setLoading(false)
          CommonNotify('The name has already been taken.', 'error')
        })
    } else {
      axios
        .post(url, { name: segmentText, contactIds })
        .then(res => {
          fetchSegments()
          setLoading(false)
        })
        .catch(err => {
          setLoading(false)
          CommonNotify('The name has already been taken.', 'error')
        })
    }

  }
  const handleDelete = item => {
    setSearchText(searchText?.filter(i => i !== item))
  }
  const activitiesColor = value => {
    var icon = ''
    var filName = ''
    switch (value) {
      case 'activity':
        icon = `${crosGray}`
        filName = 'activity'
        break
      case 'assigned agent':
        icon = `${assignAgent}`
        filName = 'assigned agent'
        break
      case 'country':
        icon = `${countryIcon}`
        filName = 'country'
        break
      case 'city':
        icon = `${city}`
        filName = 'city'
        break
      case 'group':
        icon = `${group}`
        filName = 'group'
        break
      case 'name':
        icon = `${nameIcon}`
        filName = 'name'
        break
      case 'email':
        icon = `${email}`
        filName = 'email'
        break
      case 'returning customer':
        icon = `${returnCustomer}`
        filName = 'returning customer'
        break
      case 'number of visits':
        icon = `${visitNo}`
        filName = 'number of visits'
        break
      case 'web crawlers':
        icon = `${WebCrawlers}`
        filName = 'web crawlers'
        break
      case 'ipaddress':
        icon = `${ipAddress}`
        filName = 'ipaddress'
        break
      case 'came from':
        icon = `${cameFrom}`
        filName = 'came from'
        break
      case 'last page title':
        icon = `${lastPageTitle}`
        filName = 'last page title'
      default:
        break
    }
    return { icon, filName }
  }

  /*  const getOwner = async () => {
    await getLeadOwner()
      .then(async res => {
        const admin = res.data.data.admin

        await allMembers.push({
          key: admin.id,
          text: admin.first_name + ' ' + admin.last_name,
          value: admin.first_name + ' ' + admin.last_name
        })

        await res.data.data.members.map(team => {
          allMembers.push({
            key: team.id,
            text: team.first_name + ' ' + team.last_name,
            value: team.first_name + ' ' + team.last_name
          })
        })

        setMembers(allMembers)
      })
      .catch(err => {})
  } */

  const filterData = value => {
    setLoading(true)
    if (radioState == 'is') {
      let searchableData = contactData?.filter(search => {
        return search[iconValue] === value[0]
      })
      setData(searchableData)
      setLoading(false)
    }
    if (radioState == 'isNot') {
      let searchableData = contactData?.filter(
        search => search[iconValue] !== value[0]
      )
      setData(searchableData)
      setLoading(false)
    }
  }
  const onclickSegment = e => {
    const value =
      e.target.parentNode?.childNodes[0]?.childNodes[0]?.childNodes[0]?.value
    filterData(value ? value : searchText)
  }
  const modalOpen = () => {
    setOpen(true)
  }

  const modalClose = () => {
    setOpen(false)
  }
  const onActivityChange = (e, data) => {
    setLoading(true)
    setSelectedDate({ date: data?.value, type: 'all' })
    if (data?.value === 'Select custom') {
      setSelectedPickerOption(true)
      return
    } else {
      setSelectedPickerOption(false)
    }
    //fetchContactByDate(data?.value)
  }

  const resContacts = useGetContactsQuery(selectedDate)

  const fetchAllContacts = async () => {
    setLoading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/contacts-all?order_by=name`
    axios
      .get(url)
      .then(res => {
        setLoading(false)
        var d = []
        if (res?.data) {
          const responseTwo = res.data.data?.filter(contact => {
            return contact.type == 'individual'
          })
          setContactData(responseTwo)

          const responseCompany = res.data.data?.filter(contact => {
            return contact.type == 'organization'
          })
          setCompanyData(responseCompany)

          res.data.data.map(companies => {
            if (companies.type == 'organization') {
              const response = res.data.data?.filter(contact => {
                return contact.type == companies.type
              })
              setLength(response?.length)
            }
          })
        }
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const fetchContactById = async id => {
    const apiToken = await localStorage.getItem('access_token')

    setLoading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/${id}`
    axios
      .get(url)
      .then(res => {
        setLoading(false)
        setSingleData({ ...res?.data?.data })
      })
      .catch(error => { })
  }

  const fetchContactByIdModal = async id => {
    const apiToken = await localStorage.getItem('access_token')

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/${id}`
    axios
      .get(url)
      .then(res => {
        setLoading(false)
        modalOpen()
        setSingleData({ ...res?.data?.data })
      })
      .catch(error => { })
  }

  const onclickOwner = async data => {
    const apiToken = await localStorage.getItem('access_token')

    setLoading(true)
    const owners_id = await localStorage.getItem('id')
    var datas = {
      owner_id: owners_id
    }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/${data.id}/info`
    axios
      .post(url, datas)
      .then(res => {
        setLoading(false)
        CommonNotify('Successfully updated!', 'success')
      })
      .catch(error => { })
  }

  const onClickFilter = async () => {
    setLoading(true)
    const apiToken = await localStorage.getItem('access_token')

    var datas = {
      searchKey: filterText
    }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/search`
    axios
      .post(url, datas)
      .then(res => {
        setLoading(false)
        if (res?.data) {
          const responseTwo = res.data.data?.filter(contact => {
            return contact.type == 'individual'
          })
          setContactData(responseTwo)
          setVipState(true)
          const responseCompany = res.data.data?.filter(contact => {
            return contact.type == 'organization'
          })
          setCompanyData(responseCompany)

          res.data.data.map(companies => {
            if (companies.type == 'organization') {
              const response = contactData?.filter(contact => {
                return contact.type == companies.type
              })
              setLength(response?.length)
            }
          })
        }
      })
      .catch(error => { })
  }

  // const fetchContactByDate = async data => {
  //   const apiToken = await localStorage.getItem('access_token')
  //   setLoading(true)
  //   const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/filter-contacts-by-date`
  //   axios
  //     .post(url, { date: data, type: 'all' })
  //     .then(res => {
  //       setLoading(false)
  //       const responseTwo = res.data.data?.filter(contact => {
  //         return contact.type == 'individual'
  //       })
  //       setContactData(responseTwo)
  //       const responseCompany = res.data.data?.filter(contact => {
  //         return contact.type == 'organization'
  //       })
  //       setCompanyData(responseCompany)
  //       res.data.data.map(companies => {
  //         if (companies.type == 'organization') {
  //           const response = contactData?.filter(contact => {
  //             return contact.type == companies.type
  //           })
  //         }
  //       })
  //       const r = res.data.data?.filter(contact => {
  //         return contact.type == 'organization'
  //       })

  //       setLength(r ? r : '')
  //     })
  //     .catch(error => {
  //       setLoading(false)
  //     })
  // }

  const filterDataWithKey = async () => {
    const apiToken = await localStorage.getItem('access_token')

    setLoading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/search`
    axios
      .post(url, { searchKey: iconValue })
      .then(res => {
        setLoading(false)
        const responseTwo = res.data.data?.filter(contact => {
          return contact.type == 'individual'
        })
        setContactData(responseTwo)
        const responseCompany = res.data.data?.filter(contact => {
          return contact.type == 'organization'
        })
        setCompanyData(responseCompany)
        res.data.data.map(companies => {
          if (companies.type == 'organization') {
            const response = contactData?.filter(contact => {
              return contact.type == companies.type
            })
          }
        })
        const r = res.data.data?.filter(contact => {
          return contact.type == 'organization'
        })

        setLength(r?.length)
      })
      .catch(error => { })
  }

  const handleSearch = async value => {
    const apiToken = await localStorage.getItem('access_token')

    setLoading(false)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/search`

    axios.post(url, { searchKey: value }).then(res => {
      if (res.data) {
        setLoading(true)
        const responseTwo = res.data.data?.filter(contact => {
          return contact.type == 'individual'
        })
        setContactData(responseTwo)
        const responseCompany = res.data.data?.filter(contact => {
          return contact.type == 'organization'
        })
        setCompanyData(responseCompany)
      }
    })
  }

  const handleDeleteGrp = id => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/list/${id}`
    axios
      .delete(url)
      .then(res => {
        if (res?.data?.message[0] === 'success.message') {
          CommonNotify('Group deleted successfully', 'success')
          fetchSegments()
        }
      })
      .catch(error => { })
  }

  const fetchCallLogs = async id => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/${id}/logs?segment_logs_by=month`
    axios
      .get(url)
      .then(res => {
        setLogData(res?.data?.data)
        setLoading(false)
      })
      .catch(error => { })
  }

  const fetchSegments = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/lists?contact_type=${activeIndex ? 'organization' :'individual'}`
    axios
      .get(url)
      .then(res => {
        setSegementData(res?.data?.data)
      })
      .catch(error => { })
  }
  const fetchLists = async (onComplete = () => {}) => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/lists`
    axios
      .get(url)
      .then(res => {
        setListData(res?.data?.data)
        onComplete()
      })
      .catch(error => { onComplete() })
  }
  const handleClick = () => {
    setOpenList(!open)
  }

  const handleFilter = id => {
    setSelectedContactsTab(id)
    if (id) {
      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/list/${id}`
      axios
        .get(url)
        .then(res => {
          let contactData = res?.data?.data?.contacts
          const responseTwo = contactData?.filter(contact => {
            return contact.type == 'individual'
          })
          setContactData(responseTwo)

          const responseCompany = contactData?.filter(contact => {
            return contact.type == 'organization'
          })
          setCompanyData(responseCompany)
          setLength(responseCompany?.length)
        })
        .catch(error => { })
    } else {
      let contactData = allData
      const responseTwo = contactData?.filter(contact => {
        return contact.type == 'individual'
      })
      setContactData(responseTwo)

      const responseCompany = contactData?.filter(contact => {
        return contact.type == 'organization'
      })
      setCompanyData(responseCompany)
      setLength(responseCompany?.length)
    }
  }

  useEffect(() => {
    if (props.teamMembers) {
      const allMembers = []
      const admin = props.teamMembers.data.admin
      allMembers.push({
        key: admin.id,
        text: admin.first_name + ' ' + admin.last_name,
        value: admin.first_name + ' ' + admin.last_name
      })

      props.teamMembers.data.members.map(team => {
        allMembers.push({
          key: team.id,
          text: team.first_name + ' ' + team.last_name,
          value: team.first_name + ' ' + team.last_name
        })
      })
      setMembers(allMembers)
    } else {
      props.getTeamMembers()
    }
  }, [props.teamMembers])

  useEffect(() => {
    if (props.callerId) {
      let data = []
      data =
        props.callerId.data &&
        props.callerId.data.map(number => {
          return {
            key: number[0],
            value: number[0],
            text: `LimeCall Number (${number[0]})`
          }
        })
      setNumberDropdown(data)
    } else {
      props.getCallerId()
    }
  }, [props.callerId])

  useEffect(() => {
    fetchSegments()
    fetchLists()
  }, [activeIndex])

  useEffect(() => {
    setLoading(true)
    if (resContacts.isSuccess) {
      setLoading(false)
      let contactData = resContacts.data.data
      if (selectedDate.date === 'all') {
        setAllData(contactData)
      }
      const responseTwo = contactData?.filter(contact => {
        return contact.type == 'individual'
      })
      setContactData(responseTwo)

      const responseCompany = contactData?.filter(contact => {
        return contact.type == 'organization'
      })
      setCompanyData(responseCompany)

      resContacts.data.data.map(companies => {
        if (companies.type == 'organization') {
          const response = contactData?.filter(contact => {
            return contact.type == companies.type
          })
          setLength(response?.length)
        }
      })
    }
    //getOwner()
    //getUserId()
    setFilter(contactData)
  }, [resContacts])

  const handleTabChange = (e, { activeIndex }) => {
    setActiveIndex(activeIndex)
    if (+activeIndex === 0) {
      activeType !== 'contact' && setActiveType('contact')
    } else {
      activeType !== 'company' && setActiveType('company')
    }
  }

  const fetchDataOnSave = () => {
    fetchAllContacts()
  }

  const onClickData = id => {
    fetchContactById(id)
  }

  const onClickDataModal = id => {
    fetchContactByIdModal(id)
  }

  const fetchId = id => {
    onclickOwner(id)
  }
  const onCallLogs = id => {
    fetchCallLogs(id)
  }

  const isModalClose = () => {
    setMessageModal(false)
  }

  const isModalOpen = () => {
    setMessageModal(true)
  }

  const openExportFile = e => {
    const dropDown = document.querySelector('.holder-export-options')
    dropDown.classList.toggle('show')
  }

  const messageCall = async (phone_number, id) => {
    if (phone_number) {
      setPhoneNumber(phone_number)
      setMessageModal(true)
      setContactID(id)
    }
  }

  const activeData = { 0: 'individual', 1: 'organization' }

  const panes = useMemo(() => {
    const data = [
      {
        menuItem: `Contact (${contactData?.length || 0})`,
        render: ({
          contactData,
          showExport,
          filter,
          modalOpen,
          opendetails,
          onClickData,
          fetchAllContacts
        }) => (
          <ContactsPanels
            onClickUnmarkCheckboxReset={onClickUnmarkCheckbox2}
            onClickUnmarkCheckbox={onClickUnmarkCheckbox}
            onClickMarkCheckbox={onClickMarkCheckbox}
            onClickMarkAllCheckbox={onClickMarkAllCheckbox}
            isMarkAllCheckbox={isMarkAllCheckbox}
            setisMarkAllCheckbox={setisMarkAllCheckbox}
            companyList={companyData}
            contactData={contactData}
            filter={filter}
            modalOpen={modalOpen}
            opendetails={opendetails}
            onClickData={onClickData}
            onClickDataModal={onClickDataModal}
            fetchId={fetchId}
            onCallLogs={onCallLogs}
            modalClose={modalClose}
            messageCall={messageCall}
            setOpen={setOpens}
            setActiveType={setActiveType}
            showExport={showExport}
            fetchAllContacts={fetchAllContacts}
          />
        )
      },
      {
        menuItem: `Companies (${companyData?.length || 0})`,
        render: contactData => (
          <CompanyDetailsMain
            onClickUnmarkCheckboxReset={onClickUnmarkCheckbox}
            onClickUnmarkCheckbox={onClickUnmarkCheckbox2}
            onClickMarkCheckbox={onClickMarkCheckbox2}
            onClickMarkAllCheckbox={onClickMarkAllCheckbox2}
            isMarkAllCheckbox={isMarkAllCheckbox2}
            setisMarkAllCheckbox={setisMarkAllCheckbox2}
            companyList={companyData}
            contactData={companyData}
            filter={filter}
            messageCall={messageCall}
            modalOpen={modalOpen}
            opendetails={opendetails}
            onClickData={onClickData}
            onCallLogs={onCallLogs}
            fetchId={fetchId}
            setActiveType={setActiveType}
            showExport={showExport}
            fetchAllContacts={fetchAllContacts}
          />
        )
      },
      {
        menuItem: `Lists (${listData?.length || 0})`,
        render: contactData => (
          <ListMain
            listData={listData}
            onClickUnmarkCheckboxReset={onClickUnmarkCheckbox}
            onClickUnmarkCheckbox={onClickUnmarkCheckbox2}
            onClickMarkCheckbox={onClickMarkCheckbox2}
            onClickMarkAllCheckbox={onClickMarkAllCheckbox2}
            isMarkAllCheckbox={isMarkAllCheckbox2}
            setisMarkAllCheckbox={setisMarkAllCheckbox2}
            fetchLists={fetchLists}
          />
        )
      }
    ]
    if (saveSegment) {
      data.splice(2, 0, {
        menuItem: `${segmentText} (${dataVip?.length || 0})`,
        render: () => (
          <VipFilterData
            dataVip={dataVip}
            modalOpen={modalOpen}
            opendetails={opendetails}
            onClickData={onClickData}
          />
        )
      })
    }
    setLoading(false)
    return data
  }, [contactData, saveSegment, dataVip, segmentText, companyData, listData])

  const filterApply = async () => {
    filterDataWithKey()
  }

  const closeUploadModal = () => {
    setUploadModal(false)
  }

  const openUploadModal = () => {
    setUploadModal(true)
  }

  const saveName = async () => { 
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/list/${editId}`
   await axios
      .post(url , {name:editedName})
      .then(res => {
        fetchSegments()
        setEditId('')
      })
      .catch(error => { })

}

  const handleApply = (event, picker) => { }

  const handleCancel = () => { }

  const handleEnterKeyPress = event => {
    const code = event.keyCode || event.which
    if (code === 13) {
      onClickFilter()
    }
  }

  const handleUpdateContactBulk = value => {
    if (value) {
      const ids = showExport
        ? getSelectedContact().map(el => el.id)
        : getSelectedContact2().map(el => el.id)
      if (ids?.length === 0) {
        CommonNotify('Select contact first.')
        return
      }
      const postData = {
        contact_ids: ids,
        ...value
      }
      setLoading(true)
      const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/bulk/update-info`
      axios
        .post(URL, postData)
        .then(res => {
          fetchAllContacts()
        })
        .catch(err => {
          setLoading(false)
          CommonNotify(
            err?.response?.data?.errors?.[0] || 'Something went to wrong!'
          )
        })
    }
  }

  return (
    <>
      <style>
        {`
      .ui.active.dimmer {
        z-index: 1000 !important;
      }
      `}
      </style>
      <div className="meet-container" style={{ marginTop: 80 }}>
        <EverGreenUploadCsv
          closeUploadModal={closeUploadModal}
          visible={showUpload}
          fetchAllContacts={fetchAllContacts}
        ></EverGreenUploadCsv>
        <Dimmer
          active={
            Loading || (opendetails && Object?.keys(getData)?.length === 0)
          }
          style={{ position: 'fixed' }}
        >
          <Loader />
        </Dimmer>
        <Title
          data={title}
          filterOptions={filterOptions}
          contactData={contactData}
          handleSearch={handleSearch}
          opendetails={opendetails}
        />
        <Modal
          open={listModal}
          onClose={e => setListModal(false)}
          size="tiny"
          as={'form'}
          onSubmit={e => {
            e.preventDefault()
            setSegmentModal(false)
            segmentHandleClick(e)
            setSelectedSegment(true)
            setListModal(false)
          }}
        >
          <Modal.Header>Enter List name</Modal.Header>
          <Modal.Content style={{ padding: '20px' }}>
            {/* <Input
              type="text"
              fluid
              placeholder="type List name"
              value={segmentText}
              onChange={e => handleSegmentChange(e)}
            /> */}
            <Autocomplete
              id="free-solo-demo"
              freeSolo
              value={segmentText}
              onChange={(event, newValue) => setTextSegment(newValue)}
              options={segementData.map(option => option.name)}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Type Group Name"
                  onChange={e => setTextSegment(e.target.value)}
                />
              )}
            />
          </Modal.Content>
          <Modal.Actions>
            <CommonButton
              onClick={() => setListModal(false)}
              type="button"
              content="Cancel"
              background="red"
              style={{
                borderRadius: 0,
                backgroundColor: '#e0e1e2',
                color: '#5a5a5a'
              }}
            />

            <CommonButton
              type="submit"
              content="Save"
              background="blue"
              style={{
                marginLeft: 20,
                marginRight: 20,
                borderRadius: 0,
                backgroundColor: '#1071eb',
                color: '#ffffff'
              }}
            />
          </Modal.Actions>
        </Modal>
      </div>
      <div className="meet-holder">
        <div className="meet-title-holder">
          <div className="schedule_meeting">
            <div className="scheduleFilterWrap">
              <div className="relative" style={{ display: 'flex' }}>
                  <CommonInput
                    placeholder="Search.."
                    onChange={handlefilterText}
                    type="text"
                    title=""
                    background="white"
                    inputStyle="input-style name-number-card"
                    name="cardName"
                    style={{border: '1px solid #ccc'}}
                    onKeyPress={handleEnterKeyPress}
                    icon=  {<SearchIcon
                    onClick={onClickFilter}
                    className="addcontactIcon"
                    style={{float: 'right' , position:'absolute', right: '12px' }}
                  />}
                  />
                

                  {/* <img
                    src={filterIcon}
                    onClick={onClickFilter}
                    className="addcontactIcon"
                  /> */}
              </div>

              {filterModal ? (
                <Modal open={filterModal} className="modalFilterMain">
                  <Modal.Header className="filterHeadercontent">
                    <div className="modalfilterWrap">
                      <div className="filterHead">
                        <img
                          src={`${activitiesColor(iconValue).icon}`}
                          className="inputIcon"
                        />
                        <h3>{activitiesColor(iconValue).filName}</h3>
                      </div>

                      <img
                        src={closeGray}
                        onClick={() => setModal(false)}
                        className=""
                      />
                    </div>
                  </Modal.Header>
                  <Modal.Content image>
                    <Modal.Description>
                      <div></div>
                    </Modal.Description>
                  </Modal.Content>
                  <div className="filterInput">
                    <form
                      className="tagInput"
                      onSubmit={e => {
                        e.preventDefault()
                        setVipState(true)
                        onclickSegment(e, false)
                        setModal(false)
                      }}
                    >
                      <CommonInput
                        placeholder="Search.."
                        onChange={handlefilterText}
                        type="text"
                        title="Search.."
                        background="gray"
                        inputStyle="input-style name-number-card"
                        name="cardName"
                      />
                      <div className="btnfilterOpen">
                        <Button onClick={onClickFilter}>Apply</Button>
                      </div>
                    </form>
                  </div>
                  <div className="btnfilterOpen"></div>
                </Modal>
              ) : (
                ''
              )}
              {vipstate ? (
                <>
                  {searchText?.map(item => {
                    return (
                      <div className="tag-item">
                        <Button className="chattingbtn">
                          <img
                            src={`${activitiesColor(iconValue).icon}`}
                            className=""
                          />
                          <label> {item}</label>
                          <img
                            src={closeIcon}
                            onClick={() => handleDelete(item)}
                            className=""
                          />
                        </Button>
                      </div>
                    )
                  })}
                </>
              ) : (
                ''
              )}
            </div>
            {segmentModal ? (
              <Modal open={segmentModal} className="segmentTextModal">
                <div className="segmentHead">
                  <h4>Segment</h4>
                  <img
                    src={closeGray}
                    onClick={() => {
                      setLoading(false)
                      setSegmentModal(false)
                    }}
                    className=""
                  />
                </div>
                <form
                  onSubmit={e => {
                    e.preventDefault()
                    setSegmentModal(false)
                    segmentHandleClick(e)
                  }}
                >
                  <Input
                    type="text"
                    placeholder="type list"
                    value={segmentText}
                    onChange={e => handleSegmentChange(e)}
                  />
                  <Button className="segmentbtn" onClick="" type="submit">
                    Submit
                  </Button>
                </form>
              </Modal>
            ) : (
              ''
            )}
            <div style={{ marginLeft: 30 }} className="filterSection">
              <div className="metting-filter-section">
                <div className="date-selct-metting">
                  <Dropdown
                    placeholder="Select Date"
                    onChange={(e, data) => onActivityChange(e, data)}
                    options={options}
                    selection
                    className="datedropdown"
                  />

                  <img
                    src={calendarIcon}
                    style={{ right: '21px' }}
                    width="17px"
                    className="calenderIcon"
                  />
                </div>
              </div>

              <div style={{ marginRight: 30 }} className="filterSection">
                {selectedPickerOption ? (
                  <CommonDateRangePicker
                    handleApply={handleApply}
                    handleCancel={handleCancel}
                    initialSettings={{
                      startDate: startDateFilter,
                      endDate: endDateFilter,
                      maxDate: moment()
                    }}
                  />
                ) : null}
              </div>
              {!hideButton && (
                <>
                  <Button
                    className="contact-button"
                    onClick={() => setAddOpen(true)}
                  >
                    <img src={adduser} className="addcontactIcon" />
                    Add new contact
                  </Button>

                  <Button
                    className="contact-button"
                    onClick={() => openUploadModal()}
                  >
                    <i
                      className="fa fa-upload"
                      style={{ marginLeft: -25, marginRight: 10 }}
                      aria-hidden="true"
                    ></i>
                    Import contacts
                  </Button>
                </>
              )}
              {hideButton && (
                <>
                  <div className="d-flex mt-2 mr-4">
                    <div className="holder-btn-export mr-3" title="Status">
                      <Popup
                        className="profile_popup"
                        content={
                          <BulkOperationMenu
                            options={status.map(option => ({
                              key: option,
                              text: option,
                              value: option
                            }))}
                            saveOption={tag =>
                              handleUpdateContactBulk({
                                status: tag
                              })
                            }
                          />
                        }
                        size="large"
                        position="bottom center"
                        flowing
                        on="click"
                        trigger={
                          <Button
                            className="contact-button"
                            // onClick={() =>
                            //   exportToExcel(
                            //     showExport
                            //       ? getSelectedContact()
                            //       : getSelectedContact2(),
                            //     'selected-contacts'
                            //   )
                            // }
                          >
                            <img alt="dot" src={dotIcon} height={15} />
                            Bulk add tags
                          </Button>
                        }
                      />
                    </div>
                    <div className="holder-btn-export mr-3" title="Score">
                      <Popup
                        className="profile_popup"
                        content={
                          <BulkOperationMenu
                            options={score.map(option => ({
                              key: option,
                              text: option,
                              value: option
                            }))}
                            saveOption={tag =>
                              handleUpdateContactBulk({
                                score: tag
                              })
                            }
                          />
                        }
                        size="large"
                        position="bottom center"
                        flowing
                        on="click"
                        trigger={
                          <Button
                            className="contact-button"
                            // onClick={() =>
                            //   exportToExcel(
                            //     showExport
                            //       ? getSelectedContact()
                            //       : getSelectedContact2(),
                            //     'selected-contacts'
                            //   )
                            // }
                          >
                            <img alt="dot" src={leadScoreIcon} height={15} />
                            Update lead score
                          </Button>
                        }
                      />
                    </div>
                    {/* <div className="holder-btn-export" title="Type">
                      <Popup
                        className="profile_popup"
                        content={
                          <BulkOperationMenu
                            options={type.map(option => ({
                              key: option,
                              text: option,
                              value: option
                            }))}
                            saveOption={tag =>
                              handleUpdateContactBulk({
                                type: tag
                              })
                            }
                          />
                        }
                        size="large"
                        position="bottom center"
                        flowing
                        on="click"
                        trigger={
                          <div
                            style={{
                              height: '20px',
                              width: '20px'
                            }}
                            className="image-size"
                          >
                        
                            <img alt="dot" src={leadStageIcon} />
                          </div>
                        }
                      />
                    </div> */}
                  </div>
                </>
              )}

              {(showExport || showExport2) && (
                <>
                  <Button
                    className="contact-button"
                    onClick={() =>
                      exportToExcel(
                        showExport
                          ? getSelectedContact()
                          : getSelectedContact2(),
                        'selected-contacts'
                      )
                    }
                  >
                    <i
                      className="fa fa-download"
                      style={{ marginLeft: -25, marginRight: 10 }}
                      aria-hidden="true"
                    ></i>
                    Export
                  </Button>

                  <Button
                    className="contact-button"
                    onClick={() => setDeleteModal(true)}
                  >
                    <i
                      className="fa fa-trash"
                      style={{ marginLeft: -25, marginRight: 10 }}
                      aria-hidden="true"
                    ></i>
                    Delete
                  </Button>
                  <Button
                    className="contact-button"
                    onClick={() => setListModal(true)}
                  >
                    <i
                      className="fa fa-plus"
                      style={{ marginLeft: -25, marginRight: 10 }}
                      aria-hidden="true"
                    ></i>
                    Add to list
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {addOpen ? (
        <AddContactModal
          fetchDataOnSave={fetchDataOnSave}
          Agentmembers={Allmember ? Allmember : []}
          setAddOpen={setAddOpen}
          fetchAllContacts={fetchAllContacts}
          addOpen={addOpen}
          contactData={contactData}
          countryOptions={countryOptions}
          companyLoadedData={companyData}
        />
      ) : (
        ''
      )}
      <div className="metting-event" style={{ display: 'flex' }}>
        <List
          sx={{ width: 250, minWidth: 250, bgcolor: 'background.paper' }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            <ListItemButton
              onClick={() => handleFilter()}
              sx={
                !selectedContactsTab
                  ? { borderLeft: '5px solid #009FAD !important' }
                  : { borderLeft: '5px solid transparent !important' }
              }
            >
              <ListItemText
                primary={activeIndex === 1 ? 'All Companies' : 'All Contacts'}
              />
            </ListItemButton>
            <ListItemButton>
              <ListItemText primary="Lists" onClick={handleClick} />
              {/* <PlusIcon style={{width:'20px'}}/> */}
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                { (activeIndex === 1 ||activeIndex === 0) ? 
                 segementData
                  ?.filter(obj =>
                    activeIndex === 1
                      ? obj?.type === 'organization'
                      :  obj?.type === 'individual'
                  )
                  ?.map(el => (
                    <ListItem
                      disablePadding
                      onMouseEnter={() =>
                        setShowTrashIcon({ id: el.id, isShow: true })
                      }
                      onMouseLeave={() =>
                        setShowTrashIcon({ id: el.id, isShow: false })
                      }
                      sx={
                        selectedContactsTab === el.id
                          ? { borderLeft: '5px solid #009FAD !important' }
                          : { borderLeft: '5px solid transparent !important' }
                      }
                    >
                      <ListItemButton
                        onClick={() => handleFilter(el.id)}
                        disableRipple={editId == el?.id}
                      >
                        <ListItemText
                          primary={
                            <Box display="flex" justifyContent="flex-start">
                              {editId === el?.id ? (
                                <input
                                  value={editedName}
                                  className="w-50"
                                  onChange={e => {
                                    setName(e.target.value)
                                  }}
                                  onKeyDown={e => {
                                    if (e.key === 'Enter') {
                                      saveName()
                                    }
                                  }}
                                />
                              ) : (
                                <Typography mr={1}>{el.name}</Typography>
                              )}

                              <Typography style={{ paddingRight: '10px' }}>
                                ({el.contacts_count})
                              </Typography>
                              {showTrashIcon?.id === el.id &&
                              showTrashIcon?.isShow ? (
                                <Typography style={{ marginLeft: 'auto' }}>
                                  <i
                                    style={{
                                      color: 'red',
                                      paddingRight: '15px'
                                    }}
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                    onClick={() => handleDeleteGrp(el.id)}
                                  ></i>
                                  <i
                                    className="fa fa-edit"
                                    aria-hidden="true"
                                    onClick={() => {
                                      setName(el.name)
                                      setEditId(el.id)
                                    }}
                                  ></i>
                                </Typography>
                              ) : (
                                ''
                              )}
                            </Box>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  )) : null}
                <ListItem>
                  <ListItemButton onClick={() => setOpenListModel(true)}>
                    Create List{' '}
                    <PlusIcon
                      style={{ marginLeft: '5px' }}
                      width={20}
                      height={20}
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            </Collapse>
          </List>
        </List>
        <Tab
          activeIndex={activeIndex}
          menu={{ secondary: true, pointing: true }}
          panes={panes}
          contactData={contactData}
          onTabChange={handleTabChange}
          filter={filter}
          modalOpen={modalOpen}
          opendetails={opendetails}
          onClickData={onClickData}
        />
      </div>
      {Object?.keys(getData)?.length > 0 && (
        <ContactModal
          messageCall={messageCall}
          setActiveType={setActiveType}
          activeType={activeType}
          opendetails={opendetails}
          modalClose={modalClose}
          contactData={contactData}
          companyList={companyData}
          fetchAllContacts={fetchAllContacts}
          getData={getData}
          logData={logData}
          fetchId={fetchId}
          fetchContactById={fetchContactById}
        />
      )}

      <MessageToContact
        contactID={contactID}
        isModalClose={isModalClose}
        isModalOpen={isModalOpen}
        open={messageModal}
        dropDownData={numberDropdown}
        phone_number={phone_number}
      />
      <Modal
        className="local-number-modal"
        open={deleteModal}
        onClose={e => setDeleteModal(false)}
      >
        <Modal.Content>
          <div className="modal-content">
            <p style={{ marginTop: 20 }}>
              Are you sure you want to delete this contact ?
            </p>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                paddingTop: 10,
                paddingBottom: 10
              }}
            >
              <CommonButton
                onClick={e => setDeleteModal(false)}
                type="button"
                content="Cancel"
                background="red"
                style={{
                  borderRadius: 0,
                  backgroundColor: '#e0e1e2',
                  color: '#5a5a5a'
                }}
              />

              <CommonButton
                onClick={() =>
                  showExport ? deleteSelected() : deleteSelected2()
                }
                type="button"
                content="Confirm"
                background="blue"
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  borderRadius: 0,
                  backgroundColor: '#1071eb',
                  color: '#ffffff'
                }}
              />
            </div>
          </div>
        </Modal.Content>
      </Modal>
      <Modal
        className="local-number-modal"
        open={openListModel}
        onClose={e => setOpenListModel(false)}
      >
        <Modal.Header>Add List</Modal.Header>
        <Modal.Content>
          <div className="modal-content">
            <div style={{ padding: '15px' }}>
              <label style={{ marginRight: '10px' }}>Add List : </label>
              <input
                style={{ height: '40px' }}
                placeholder="Enter your list here"
                onChange={e => setSaveList(e.target.value)}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                paddingTop: 10,
                paddingBottom: 10
              }}
            >
              <CommonButton
                onClick={e => setOpenListModel(false)}
                type="button"
                content="Cancel"
                background="red"
                style={{
                  borderRadius: 0,
                  backgroundColor: '#e0e1e2',
                  color: '#5a5a5a'
                }}
              />

              <CommonButton
                onClick={() =>
                  createList(activeIndex ? 'organization' : 'individual')
                }
                type="button"
                content="Add"
                background="blue"
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  borderRadius: 0,
                  backgroundColor: '#1071eb',
                  color: '#ffffff'
                }}
              />
            </div>
          </div>
        </Modal.Content>
      </Modal>
    </>
  )
}

const mapStateToProps = state => ({
  teamMembers: state.settings?.teamMembers,
  callerId: state.settings.callerId
})

export default connect(mapStateToProps, { getCallerId, getTeamMembers })(
  Contacts
)
