import React, { useState } from 'react'
import { Modal } from 'semantic-ui-react'

import CommonButtons from './CommonButtons'
import CommonInput from './CommonInput'
import PhoneInput from 'react-phone-input-2'
import Axios from 'axios'
import { CommonNotify } from './CommonNotify'
import { parsePhoneNumber } from 'libphonenumber-js'

const PhoneVerificationModal = props => {
  const [active, setActive] = useState(0)
  const [phoneNumber, setPhoneNumber] = useState(false)
  const [verificationCode, setVerificationCode] = useState()
  const handleClick = () => {
    if (active === 0) {
      sendCode()
      setActive(1)
    } else {
      confirmCode()
    }
  }

  const confirmCode = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/verify-phone-number`
    const formData = new FormData()
    formData.append('verification_code', verificationCode)
    await Axios.post(url, formData)
      .then(response => {
        props.handleModalClose()
        localStorage.setItem('mobile_verification_status', 1)
        CommonNotify('Phone number verified successfully', 'success')
      })
      .catch(error => {
        CommonNotify('Verification Code is not correct')
      })
  }
  const sendCode = async () => {
    const phoneNumberObj = parsePhoneNumber(`+${phoneNumber}`)
    console.log('phoneNumberObj', phoneNumberObj)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/send-verify-phone-number`
    const formData = new FormData()
    formData.append('full_number', `+${phoneNumber}`)
    formData.append('phone_country', phoneNumberObj?.country)
    formData.append('phone_number', `+${phoneNumber}`)
    await Axios.post(url, formData)
      .then(response => {
        CommonNotify('Code sent successfully', 'success')
      })
      .catch(error => {})
  }
  return (
    <Modal
      className="send-message-modal"
      size="tiny"
      open={props.open}
      onClose={props.handleModalClose}
      closeIcon
    >
      <Modal.Header className="modal-header">
        Please verify your number to make outgoing calls
      </Modal.Header>
      <Modal.Content>
        {active === 0 ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '15px'
            }}
          >
            {/* <h3 style={{ marginBottom: '15px' }}>
              Add your phone number to get a verification code sent to your
              phone
            </h3> */}
            <p
              style={{ marginBottom: '15px' }}
              className="modal-input-text default-text"
            >
              Phone Number
            </p>
            <PhoneInput
              placeholder="999-999-9999"
              className="text-color"
              value={phoneNumber}
              onChange={value => {
                console.log('value', value)
                setPhoneNumber(value)
              }}
            />
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '15px'
            }}
          >
            <p
              style={{ marginBottom: '15px' }}
              className="modal-input-text default-text"
            >
              Verification Code
            </p>
            <CommonInput
              style={{ marginBottom: '15px' }}
              onChange={e => setVerificationCode(e?.target?.value)}
              inputStyle="modal-input"
              name="name"
            />
          </div>
        )}
      </Modal.Content>
      <Modal.Actions
        className="send-message__btn"
        style={{
          padding: '15px',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <CommonButtons
          onClick={() =>
            active === 1 ? setActive(0) : props.handleModalClose()
          }
          content={active === 1 ? 'Back' : 'Close'}
          background="light"
          btnClass="btn-modal-style"
        />
        <CommonButtons
          onClick={handleClick}
          content={active === 0 ? 'Send Code' : 'Verify'}
          background="blue"
          btnClass="btn-modal-style"
        />
      </Modal.Actions>
    </Modal>
  )
}
export default PhoneVerificationModal
