import axios from 'axios'
import React, { useEffect, useMemo, useState } from 'react'
import { Dimmer, Loader, Modal, Tab } from 'semantic-ui-react'
import closeIcon from '../../assets/images/closecontact.png'
import CommonButtons from '../../common/CommonButtons'
import { CommonNotify } from '../../common/CommonNotify'
import AddCompanyDetails from './addCompanyDetails'
import AddContactDetails from './addContactdetails'

const AddContactModal = ({
  addOpen,
  setAddOpen,
  contactData,
  countryOptions,
  Agentmembers,
  fetchDataOnSave,
  companyLoadedData
}) => {
  const initialContactState = {
    name: '',
    email: '',
    phone_number: '',
    country: 'Afghanistan',
    city: '',
    title: '',
    owner: '',
    phone_country: '',
    type: 'individual',
    company: ''
  }
  const intialCompanyData = [
    {
      city: '',
      country: 'Afghanistan',
      name: 'Purpulerain',
      organization_name: 'Purpulerain',
      owner: '',
      phone_country: '',
      phone_number: '',
      company: '',
      type: 'organization'
    },

    {
      city: '',
      country: 'Afghanistan',
      name: 'Betacloud',
      organization_name: 'Betacloud',
      owner: '',
      phone_country: '',
      phone_number: '',
      company: '',
      type: 'organization'
    }
  ]

  const [createContact, setcreateContact] = useState(initialContactState)
  const [companyData, setCompanyData] = useState(companyLoadedData)
  const [upload, setUpload] = useState()
  const [loading, setLoading] = useState(false)
  const [activeIndex, setIndex] = useState(0)
  const [members, setMembers] = useState()

  const handleTabChange = (r, data) => setIndex(data.activeIndex)

  const handleInputChange = (event, code, type) => {
    if (type === 'phone_number') {
      setcreateContact({
        ...createContact,
        phone_number: `+${event}`,
        phone_country: code.countryCode
      })
    } else {
      const { name, value } = event.target
      createContact[name] = value
      setcreateContact(prevContactData => ({
        ...prevContactData,
        [name]: value
      }))
    }
  }
  const handleCompanyInputChange = (event, code, type) => {
    if (type === 'phone_number') {
      setCompanyData({
        ...companyData,
        phone_number: `+${event}`,
        phone_country: code.countryCode
      })
    } else {
      const { name, value } = event.target
      companyData[name] = value
      setCompanyData(prevCompanyData => ({ ...prevCompanyData, [name]: value }))
    }
  }
  const fileChangedHandler = event => {
    setUpload(event.target.files[0])
  }

  const validData = async e => {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    )
    if (!createContact?.name?.trim() && !createContact?.phone_number?.trim()) {
      setTimeout(() => CommonNotify('Please Fill the name', 'error'), 0)
      CommonNotify('Please Fill the phone number', 'error')
    } else if (!createContact?.name?.trim()) {
      CommonNotify('Please Fill the name', 'error')
    } else if (!createContact?.phone_number?.trim()) {
      CommonNotify('Please Fill the phone number', 'error')
    } else if (createContact?.email?.trim()) {
      if (!pattern.test(createContact.email)) {
        CommonNotify('Email address is invalid', 'error')
      } else {
        fetchAllContacts(e)
      }
    } else {
      fetchAllContacts(e)
    }
  }

  const fetchAllContacts = event => {
    setLoading(true)
    event.preventDefault()

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact`

    var bodyFormData = new FormData()
    bodyFormData.append('name', createContact.name)
    bodyFormData.append('phone_number', createContact.phone_number)
    bodyFormData.append('country', createContact.country)
    bodyFormData.append('phone_country', createContact.phone_country)
    bodyFormData.append('email', createContact.email)
    bodyFormData.append('type', createContact.type)
    bodyFormData.append('owner', createContact.owner)

    axios
      .post(url, bodyFormData)
      .then(res => {
        setAddOpen(false)
        setLoading(false)
        fetchDataOnSave()
        if (res?.data?.message[0] == 'success.message') {
          CommonNotify('Successfully Created Contact Data ', 'success')
        }
      })
      .catch(error => {
        setLoading(false)
        CommonNotify(
          error?.response?.data?.errors?.[0] ||
            'Contact Exists / Required field invalid !'
        )
      })
  }
  useEffect(() => {
    setMembers(Agentmembers)

    setcreateContact({
      ...createContact,
      owner: Agentmembers[0]?.value
    })

    setCompanyData({
      ...companyData,
      owner: Agentmembers[0]?.value
    })
  }, [])

  const validCompanyData = async e => {
    if (!companyData?.name?.trim() && !companyData?.phone_number?.trim()) {
      setTimeout(() => CommonNotify('Please Fill the company', 'error'), 0)
      CommonNotify('Please Fill the phone number', 'error')
    } else if (!companyData?.name?.trim()) {
      CommonNotify('Please Fill the company', 'error')
    } else if (!companyData?.phone_country?.trim()) {
      CommonNotify('Please Fill the phone number', 'error')
    } else {
      createCompanyData(e)
    }
  }

  const createCompanyData = event => {
    event.preventDefault()
    setLoading(true)
    let data = {
      name: companyData.name,
      country: companyData.country,
      organization_name: companyData.name,
      owner: companyData.owner,
      phone_number: companyData.phone_number,
      phone_country: companyData.phone_country,  
      type: 'organization',
      company: companyData.name
    }

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact`
    axios
      .post(url, data)
      .then(res => {
        setLoading(false)
        setAddOpen(false)
        fetchDataOnSave()
        CommonNotify('Successfully created Company data', 'success')
      })
      .catch(error => {
        setLoading(false)
        CommonNotify(error?.response?.data?.errors?.[0] || 'Failed to create!')
      })
  }

 const panes = useMemo(
    () => [
      {
        menuItem: { id: 'tab1', content: 'Contact' },
        render: () => (
          <AddContactDetails
            setcreateContact={setcreateContact}
            companyList={companyLoadedData}
            createContact={createContact}
            handleInputChange={handleInputChange}
            contactData={contactData}
            fileChangedHandler={fileChangedHandler}
            upload={upload}
            members={members}
          />
        )
      },
      {
        menuItem: { id: 'tab2', content: 'Company' },
        render: () => (
          <AddCompanyDetails
            companyData={companyData}
            handleCompanyInputChange={handleCompanyInputChange}
            contactData={contactData}
            countryOptions={countryOptions}
            members={members}
          />
        )
      }
    ],
    [upload, members]
 )
  return (
    <Modal open={addOpen} className="MainModal">
      <Modal.Header className="modalMainWrap">
        <h1>{activeIndex === 0 ? 'New Contact' : 'New Company'}</h1>
        <div className="modalHeadWrap">
          <img
            src={closeIcon}
            className=""
            width="18"
            onClick={() => setAddOpen(false)}
          />
        </div>
      </Modal.Header>

      {/* <div className="sectionSwitch">
        <img src={addcontact} className="" />

        <p>Associate this contact with existing</p>
        <Checkbox toggle />
      </div> */}
      <div className="tabMenuItem">
        <Tab
          menu={{ secondary: true, pointing: true }}
          panes={panes}
          activeIndex={activeIndex}
          onTabChange={handleTabChange}
        />
      </div>
      <Modal.Actions className="modalMainWrap">
        <CommonButtons
          onClick={e => {
            if (activeIndex == 0) {
              validData(e)
            } else {
              validCompanyData(e)
            }
          }}
          content={activeIndex === 0 ? 'Add Contact' : 'Add Company'}
          background="blue"
          btnClass="btn-modal-style"
        />
      </Modal.Actions>
      <Dimmer active={loading} style={{ position: 'fixed' }}>
        <Loader />
      </Dimmer>
    </Modal>
  )
}

export default AddContactModal
