import axios from 'axios'
import React, { useEffect, useState } from 'react'
import StarRatings from 'react-star-ratings'
import { Grid } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

// import editIcon from '../../../assets/images/edit-icon.svg'
import { CommonNotify } from '../../../common/CommonNotify'
import { useGetContactsQuery } from '../../../features/api/apiSlice'
import EditDetailOpen from '../../contactsPanels/editDetailsPopUp'
import QualifyFooter from './QualifyFooter'
import BlockIcon from '@mui/icons-material/Block'
import editLead from '../../../assets/images/Dashboard-35.png'
import reschedule from '../../../assets/images/reschedule.png'
import UpdateLeadModal from '../../../common/UpdateLeadModal'
import CancelIcon from '@mui/icons-material/Cancel';
import moment from 'moment'
import Reschedule from '../../../common/reScheduleModal'
import Axios from 'axios'
import DownloadIcon from '@mui/icons-material/Download';

const intialEditData = {
  name: '',
  phone_number: '',
  phone_number: '',
  email: '',
  country: '',
  type: '',
  city: '',
  owner_id: '',
  owner_name: '',
  first_name: '',
  organization_name: ''
}
const LeadFeedback = ({ leadData, leadScore, handleReGetValue }) => {
  const resContacts = useGetContactsQuery({ date: 'all', type: 'all' })
  const [companyData, setCompanyData] = useState()
  const [leadUpdated, setLeadUpdated] = useState(true)
  const [editContactModal, setEditContactModal] = useState(false)
  const [dropdownValue, setDropdownValue] = useState(null)
  const [leadDetail, setLeadDetail] = useState({
    message: leadData?.lead?.messages_within_call,
    email: leadData?.lead?.email,
    name: leadData?.lead?.customer_name
  })
  const [editContact, setEditContact] = useState(intialEditData)
  const [openLeadModal, setOpenLeadModal] = useState(false)
  const [openReschedule, setOpenReschedule] = useState(false)

  useEffect(() => {
    const widgetData = sessionStorage.getItem('widget')
    if (!widgetData) {
      getWidget()
    }
  }, [])

  useEffect(() => {
    if (openLeadModal && !dropdownValue?.length) {
      getIntrest()
    }
  }, [openLeadModal])

  useEffect(() => {
    if (resContacts.isSuccess) {
      let contactData = resContacts?.data?.data
      const responseCompany = contactData?.filter(contact => {
        return contact.type == 'organization'
      })
      setCompanyData(responseCompany)
    }
  }, [resContacts])

  useEffect(() => {
    let lUpdated = !leadUpdated
    setLeadUpdated(lUpdated)
  }, [leadData])

  const handleCancel =async(second) => { 

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${leadData.lead.id}/cancel`
    const res = await Axios.post(url)

 }


  const getIntrest = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/widgets`
    axios
      .get(url)
      .then(res => {
        const setInterestedData = res.data.data[0].interested
        const updateLeadInterest = res.data.data[0].interested.map(
          (item, index) => {
            return {
              key: index,
              text: item,
              value: item
            }
          }
        )
        setDropdownValue({
          interestData: [...updateLeadInterest],
          interestedData: [...setInterestedData]
        })
      })
      .catch(err => {})
  }

  const getWidget = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/widgets`
    axios
      .get(url)
      .then(res => {
        let tempObject = {
          id: res.data.data[0].id,
          script: res.data.data[0].script_id,
          business_hours_status: res.data.data[0].business_hours_status
        }
        localStorage.setItem('widget_id', res?.data?.data?.[0]?.id)
        localStorage.setItem('widget_active', res.data.data[0].active)
        sessionStorage.setItem('widget', JSON.stringify(tempObject))
        const setInterestedData = res.data.data[0].interested
        const updateLeadInterest = res.data.data[0].interested.map(
          (item, index) => {
            return {
              key: index,
              text: item,
              value: item
            }
          }
        )
        setDropdownValue({
          interestData: [...updateLeadInterest],
          interestedData: [...setInterestedData]
        })
      })
      .catch(err => {})
  }

  const trimUrl = (url = '') => {
    if (!url || url == '') return ''
    const newUrl = url.split('/', 3)
    if (!url?.length) return ''

    url = newUrl[newUrl?.length - 1]
    return url
  }

  // const fetchContactByIds = id => {
  //   if (id) {
  //     const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/${id}`
  //     axios
  //       .get(url)
  //       .then(res => {
  //         setEditContact({
  //           ...editContactModal,
  //           name: res?.data?.data?.name,
  //           email: res?.data?.data?.email,
  //           phone_number: res?.data?.data?.phone_number,
  //           owner_id: res?.data?.data?.owner?.id
  //             ? res?.data?.data?.owner?.id
  //             : 0,
  //           owner_name:
  //             res?.data?.data?.owner?.first_name +
  //             ' ' +
  //             res?.data?.data?.owner?.last_name
  //               ? res?.data?.data?.owner?.first_name +
  //                 ' ' +
  //                 res?.data?.data?.owner?.last_name
  //               : '',
  //           country: res?.data?.data?.country,
  //           type: res?.data?.data?.type,
  //           city: res?.data?.data?.city,
  //           first_name: res?.data?.data?.owner?.first_name,
  //           organization_name: res?.data?.data?.organization_name
  //             ? res?.data?.data?.organization_name
  //             : 'test'
  //         })
  //       })
  //       .catch(error => {
  //         CommonNotify(
  //           error?.response?.data?.errors?.[0] || 'something went to wrong!'
  //         )
  //       })
  //   }
  // }

  const handleInputChange = (event, code, type) => {
    if (type === 'phone_number') {
      setEditContact({
        ...editContact,
        phone_number: `+${event}`,
        phone_country: code.countryCode
      })
    } else {
      const { name, value } = event.target
      editContact[name] = value
      setEditContact(prevContactData => ({
        ...prevContactData,
        [name]: value
      }))
    }
  }

  const onclickOwner = () => {
    let retData = editContact
    if (editContact.owner_id == 0) {
      delete editContact.owner_id
      delete editContact.owner_name
    }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/update/${leadData?.lead?.contact?.id}`
    axios
      .post(url, retData)
      .then(res => {
        CommonNotify('Contact successfully updated!', 'success')
        setEditContactModal(false)
        setEditContact(intialEditData)
      })
      .catch(error => {
        CommonNotify(
          error?.response?.data?.errors?.[0] || 'Cant update contact!'
        )
      })
  }

  const handleBlock = value => {
    if (value) {
      const data = {
        block_reason: 'spammer',
        widget_id: localStorage.getItem('widget_id'),
        ip: value
      }
      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/blacklist-user`
      axios
        .post(url, data)
        .then(res => {
          CommonNotify('User IP Added to BlockList', 'success')
        })
        .catch(error => {
          CommonNotify(error?.response?.data?.errors?.[0], 'warning')
        })
    } else {
      CommonNotify("IP address is not available so, We can't block this IP")
    }
  }

  const handleEmailBlock = value => {
    if (value) {
      const data = {
        block_reason: 'spammer',
        widget_id: localStorage.getItem('widget_id'),
        email: value
      }
      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/blacklist-user`
      axios
        .post(url, data)
        .then(res => {
          CommonNotify('User Email Added to BlockList', 'success')
        })
        .catch(error => {
          CommonNotify(error?.response?.data?.errors?.[0], 'warning')
        })
    } else {
      CommonNotify(
        "Email address is not available so, We can't block this Email"
      )
    }
  }

  const onUpdateRequest = async data => {
    if (leadData?.lead?.id) {
      if (data.phone_number) {
        if (data.phone_number?.length < 10) {
          return CommonNotify(
            'Validation error invalid phone number',
            'warning'
          )
        }
      }
      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${leadData?.lead?.id}/info`
      const postData = {
        customer_name: data.customer_name,
        phone_number: data.phone_number,
        email: data.email,
        notes: data.interest,
        country: data.country,
        role: data.role,
        company_name: data.company,
        industry: data.industry,
        employees: data.companySize,
        lead_interest: data.lead_interest
      }
      axios
        .post(url, postData)
        .then(res => {
          CommonNotify('Lead info updated', 'success')
          setOpenLeadModal(false)
          handleReGetValue()
        })
        .catch(err => {
          CommonNotify(
            err?.response?.data?.errors?.[0] || 'Cant Update Lead Detail'
          )
        })
    }
  }

  const leads = leadData?.lead
  const message = leadDetail?.message
    ? JSON?.parse(leadDetail?.message)?.[0]?.message
    : leadDetail?.message
    
    const getFileName = (path) => { 
     const lastIndex= path.lastIndexOf('/')
      const textAfterLastSlash = path.substring(lastIndex + 1)
      return textAfterLastSlash
     }

  return (
    <div
      className="contact-card aboutTabpane"
      style={{ height: '400px !important', overflowY: 'auto !important' }}
    >
      <div className="contact-card-boxes">
        <Grid className="card-boxes-text">
          <h4>Agent Feedback:</h4>
          <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Call Outcome:</p>
            </Grid.Column>
            <Grid.Column width={12}>
              <p className="card-boxes-right-text"> {leads?.outcome}</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Agent Rating:</p>
            </Grid.Column>
            <Grid.Column width={12}>
              <p className="card-boxes-right-text">
                {' '}
                <StarRatings
                  rating={
                    !leads?.agent_feedback_rate ? 0 : leads?.agent_feedback_rate
                  }
                  starRatedColor="#f78030"
                  numberOfStars={5}
                  name="rating"
                />
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Feedback:</p>
            </Grid.Column>
            <Grid.Column width={12}>
              <p className="card-boxes-right-text">
                {leads?.agent_feedback_text}
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>{' '}
      <div className="contact-card-boxes">
        <Grid className="card-boxes-text">
          <h4>After Call Lead Feedback:</h4>
          <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Lead Rating:</p>
            </Grid.Column>
            <Grid.Column width={12}>
              <p className="card-boxes-right-text">
                {' '}
                <StarRatings
                  rating={
                    !leads?.customer_feedback_rate
                      ? 0
                      : leads?.customer_feedback_rate
                  }
                  starRatedColor="#f78030"
                  numberOfStars={5}
                  name="rating"
                />
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Feedback:</p>
            </Grid.Column>
            <Grid.Column width={12}>
              <p className="card-boxes-right-text">
                {leads?.customer_feedback_text}
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>{' '}
      {editContactModal && (
        <EditDetailOpen
          companyList={companyData}
          setDetailOpen={setEditContactModal}
          editDetailOpen={editContactModal}
          handleInputChange={handleInputChange}
          editContact={editContact}
          setEditContact={setEditContact}
          onclickOwner={onclickOwner}
        />
      )}
      {openLeadModal && (
        <UpdateLeadModal
          isModalClose={() => setOpenLeadModal(false)}
          isModalOpen={() => setOpenLeadModal(true)}
          open={openLeadModal}
          leadData={leads}
          onUpdateRequest={onUpdateRequest}
          leadId={leadData?.lead?.id}
          interested={
            (dropdownValue?.interestedData &&
              dropdownValue?.interestedData?.length &&
              dropdownValue?.interestedData) ||
            []
          }
          lead_interest={leads?.lead_interest}
        />
      )}
    </div>
  )
}

export default LeadFeedback
